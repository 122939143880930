@import "../../../globalStyles.scss";

.filter-container {
    text-align: left;
    max-width: 100%;

    .outer-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .filter-wrapper {
            //changed this to flexbox so it could wrap for all screensizes down to 768px (then it's mobile/tablet size once it's smaller than that)
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .dates-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}


.basic-input-label-wrapper {
    text-align: left;
}

.basic-input-label-wrapper {
    font-weight: bold;
    margin-top: 0.5rem;
    padding-left: 2px;
}

.basic-input-label {
    display: block !important;
    color: $primaryColor !important;
    padding: 0 0 0.2rem !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    text-transform: none !important;
}

.date-input-wrapper {
    display: flex;
    flex-direction: row;

    .field {
        margin-right: 1rem;
    }
}

.multiSelectContainer {
    padding-right: 1rem !important;

    .searchWrapper {
        min-height: 44px;
    }

    .search-wrapper {
        min-width: 300px !important;
        max-width: 300px !important;

        .chip,
        .singleChip {
            white-space: normal !important;
            margin: 0.1rem !important;
        }

        .searchBox {
            height: 0 !important;
            width: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
}

// solutions to prevent dropdown selection (selected) from spilling out of container when viewing on QA site
.chip,
.singleChip {
    white-space: normal !important;
}

span.chip.false {
    white-space: normal !important;
}