@import "../../../globalStyles.scss";

.additional-info-links {
    .link-preview {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        padding: 0.2rem 0 2rem;

        .link-card {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            background-color: #fbfbfb;
            padding: 0 0 0.5rem;
            margin: 0;
            border: 0.5px solid #dedede;
            box-shadow: $boxShadow4;

            &-url {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                text-transform: uppercase;
                padding: 0.4rem 0 0.4rem 1rem;
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                font-weight: 600;
                background-color: $wrampsYellow;
                outline: 1px solid $wrampsYellow;
                border-radius: 10px 10px 0 0;

                a:hover {
                    color: white;
                }

                a:focus {
                    color: #4183c4;
                }

                .link-card-delete {
                    cursor: pointer;
                    font-weight: bold;
                    color: #4b4b4b;
                }
            }

            &-description {
                text-align: justify;
                /* CSS3 wrap */
                white-space: pre-wrap;
                /* Firefox wrap */
                white-space: -moz-pre-wrap;
                /* Opera <7 wrap*/
                white-space: -pre-wrap;
                /* Opera 7 wrap */
                white-space: -o-pre-wrap;
                /* IE wrap */
                word-wrap: break-word;
                padding: 0.3rem 1.2rem;
                line-height: 1.1rem;
                font-weight: normal;
            }
        }
    }

    .links-title {
        font-size: 1.1rem;
        font-weight: 600;
        color: $primaryColorHov;
        padding-bottom: 1rem;
    }

    .link-display-input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        column-gap: 1rem;

        .link-display-input {
            margin: 0;
            min-height: 5.2rem;

            .input-component {
                min-width: 22rem;

                .field {
                    .basic-input-label-wrapper {
                        margin: 0;

                        label.basic-input-label {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }

        .link-display-btn {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 0 0 1.1rem;
            white-space: nowrap;
            min-height: 5.2rem; // keeps all inputs same height so when url error message appears it doesn't make UI jump

            button {
                height: 2.68rem;
            }
        }
    }
}

@media only screen and (min-width: 2200px) {
    .additional-info-links {
        .link-preview {
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5rem;

            .link-card {
                &-url {
                    padding: 1rem 0 1rem 1rem;
                    font-size: 1.5rem;
                }

                &-description {
                    line-height: 1.7rem;
                    font-size: 1.5rem;
                    padding: 1rem 1.2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .additional-info-links {
        .link-preview {
            display: flex;
            flex-wrap: wrap;

            .link-card {
                width: 13rem;

                &-url,
                &-description {
                    font-size: 0.9rem;
                }

                &-description {
                    font-size: 0.9rem;
                    padding: 0.3rem 1rem;
                    line-height: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .additional-info-links {
        .link-preview {

            .link-card {

                &-url,
                &-description {
                    font-size: 0.9rem;
                }

                &-description {
                    font-size: 0.9rem;
                    padding: 0.3rem 1rem;
                    line-height: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 481px) {
    .additional-info-links {
        .link-preview {
            .link-card {
                width: 100%;
            }
        }
    }
}