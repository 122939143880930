// this scss file (ProjectEditPages.scss) controls all the .tsx files inside folder projectEditPages (the other scss file called EditProjectPage controls EditProjectPage.tsx)

// this is universal styling for inside each tab
.edit-proj-fundamentals,
.edit-proj-planning-status,
.edit-proj-financial,
.edit-proj-attributes,
.edit-proj-benefits,
.edit-proj-o-m,
.edit-proj-additional,
.edit-proj-additional,
.edit-proj-gisdata {
    margin-top: 1rem;

    .input-component {
        .field {
            .basic-input-label-wrapper {
                .basic-input-label {}
            }

            .content {
                padding: 0.5rem;
            }
        }
    }

    .text-area-component {
        padding: 0.5rem 0;

        .field {
            .textbox {
                padding: 0.5rem;
            }
        }
    }

    .dropdown-h2o {
        margin-top: 0.5rem;

        .dropdown-title {}

        .ui.selection.dropdown {
            min-width: 100% !important;
        }
    }

    .custom-date-picker-container {
        margin: 0.5rem 0;
    }

    .addon-input-component {
        margin-bottom: 0.5rem;

        .addon-input-label-wrapper {
            .addon-input-label {
                font-size: 1rem !important;
            }
        }
    }

    .storage-capacity-toggle {
        padding: 1rem 0 0.5rem;
    }

    .mt-point5 {
        margin-top: 0.5rem !important;
    }

    .mt-1point4 {
        margin-top: 1.4rem !important;
    }

    .note {
        margin-top: 0.5rem;

        span {
            font-weight: bold;
        }
    }

    .subpage-item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0 0.8rem;

        .subpage-item-title {
            color: #006ba5;
            font-size: 1.05rem;
            font-weight: bold;
            margin-right: 0.8rem;
        }

        .subpage-item-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .input-container {
        max-width: 75% !important;
    }
}