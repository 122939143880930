// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.edit-project-sidebar-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100% !important;
    margin: 0;

    .edit-project-inner-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
        min-height: 600px;

        .header-div {
            text-align: center;
            color: $primaryColor;
            font-size: 1.5em;
            font-weight: 600;
            padding: 2rem;
            width: fit-content;

            button {
                font-size: 0.9rem;
                padding: 0.8rem 0.5rem;
                margin: 0.5rem 0 0 0.5rem !important;
                white-space: nowrap;
            }
        }

        .menu-items-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap !important;
            min-width: 100%;
            padding: 0;
            margin: 0 0 0.5rem 0.5rem;
            // the rest of styling for blue menu tabs found on globalStyles.scss
        }

        .edit-subpage-container {
            padding: 0 3rem;
            width: 90%;
        }
    }

    .wave-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        .wave {
            width: 100%;
        }
    }
}