.custom-update-sg-modal {


    &__date-range-container {
        display: flex;
        margin-bottom: 1em;
    }
    &__date-input-left {
        margin-right: 2em;
    }

    .addon-input-component .addon-input-label{
        color: rgba(0, 0, 0, .87) !important;
        margin: 1em 0 0.51em 0;
    }

    .primary:hover {
        color: white  !important;
    }
}