// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.url-input-component {

    .url-input-inner-wrapper {
        width: 22rem;

        .ui.input {
            min-width: 100%;

            input {
                padding-left: 0.5rem;
            }
        }

        .basic-input-label-wrapper {
            text-align: left;
        }

        .basic-input-label {
            display: block;
            color: $primaryColor;
            padding: 0 !important;
            font-size: 1rem;
            font-weight: 700;
            text-transform: none;
        }
    }

    .invalid-url {
        color: red;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
        line-height: 0.9rem;
    }
}