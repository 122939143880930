@use "../../globalStyles";

.popup-wrapper {
    border-radius: 8px;
    margin-bottom: 25px;

    // this hides bottom black pointer under popup
    .esri-popup__pointer {
        display: none !important;
    }

    .esri-popup__main-container,
    .custom-popup-wrapper {
        border-radius: 8px;
        max-width: 30rem;

        // this gets rid of black default popup with 'zoom to' option
        &.esri-widget {
            display: none !important;
        }

        .popup-container-wrapper {
            background: globalStyles.$mapHoverBg; // change .bottom-arrow to match bottom color of gradient - see below
            border-radius: 8px;

            .popup-container {
                .popup-icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    animation: move-cloud 2s ease infinite;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 75px 50px;
                    width: 75px;
                    height: 50px;
                    background-image: url("../../images/watercloud.png");
                    margin-top: -2.85rem;
                    margin-right: -2.3rem;
                    padding: 8px 0 0 0;
                    line-height: 0.85rem;
                    color: #0776b6;

                    .popup-value {
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    .popup-unit {
                        color: #0776b6;
                        font-weight: 500;
                    }
                }

                .popup-text {
                    text-align: center;
                    color: white;
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 10px 25px 10px 15px;
                }

                @keyframes move-cloud {
                    0% {
                        transform: translateY(-3px);
                    }

                    50% {
                        transform: translateY(0px);
                    }

                    100% {
                        transform: translateY(-3px);
                    }
                }
            }

            .bottom-arrow:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 0;
                height: 0;
                border-top: 10px solid #61b0dd;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
            }
        }
    }
}