// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

// By nesting the styles inside the wrapper container(.Sample) you can prevent styles from bleeding through to other components on accident
.date-picker-component {
    .example-of-sass {
        font-family: $fontFamilyMain;
        color: $wrampsWaterBlue;
    }

    .field {
        display: flex !important;
        flex-direction: column !important;

        label {
            color: $primaryColor !important;
            font-weight: 700 !important;
            padding: 0.5em 0 0.3em 0 !important;
            display: flex !important;
        }
    }
}