@import "../../../../globalStyles.scss";

.preview-public-elements {
    .subpage-container {
        .preview-section-wrapper {
            background-color: white;
            overflow: hidden;
            border-radius: 5px;
            box-shadow: $boxShadow1;

            .preview-section-top {
                text-align: left;
                background-color: white;
                padding: 0 1rem 0.25rem;
                border-radius: 0.5rem 0.5rem 0 0;
            }

            .view-wrapper {
                display: flex;
                flex-direction: row;
                padding-top: 1rem;

                .dashboard-button-group {
                    // global styling for button group on globalStyles.scss
                }

                .ui.message.view-description {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0.3rem 0.75rem;
                    margin-top: 0.1rem;
                    line-height: 1.4rem;

                    i.icon {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    }
                }
            }

            .ui.message {
                margin-bottom: 0.75rem;
            }

            .preview-header {
                font-size: 1.3rem;
                font-weight: 600;
                text-align: center;
                padding: 0;
            }
        }

        .dashed-line {
            height: 1rem;
            border-bottom: 1px dashed rgb(176, 176, 176);
        }

        .no-public-sections {
            font-size: 1.2rem;
            font-weight: 600;
            color: red;
            padding: 1rem 2rem 2rem;
        }

        .ui.segment {

            .ui.active.visible.dimmer {
                border-radius: 5px !important; // loading dimmer
            }
        }

        // preview section content styling found in PublicElementSection.scss
    }

    .preview-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1.5rem;

        &-text {
            margin-right: 1rem;
            font-weight: 600;
            color: $primaryColor;
            text-decoration: underline;
            cursor: pointer;
        }

        &-btn {
            .ui.button {
                font-family: "Poppins", sans-serif;
                font-weight: 600;
            }
        }
    }
}