@import "../../globalStyles.scss";

.main-wrapper {
    height: 100vh !important;

    .main-wrapper-container {
        display: flex;
        min-width: 100%;

        .controls-container {
            padding: 10px 5px 0;
            margin-right: 2px;
            background-color: $bggrey;
            box-shadow: 1px 0 1px 0 lightgray;

            .control-div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 3px;
                font-size: 2rem;
                cursor: pointer;
                ;

                .control-icon {
                    margin: 0;
                    color: $navbarBlue;
                    transition: all 0.2s ease-in-out;
                }

                //hover & active are the same
                .control-icon:hover,
                .active-controls {
                    transform: scale(1.3);
                    color: $primaryColor;
                }
            }
        }
    }

    .ui.segment {
        box-shadow: none !important;
        border: none !important;
        border-radius: 0 !important;
    }

    .sidebar-pushable {
        margin-top: 0 !important;
        min-height: calc(100vh - #{$navHeightDesktop});
    }
}

// this hits small screens
@media screen and (max-width: 700px) {
    .main-wrapper-container {
        flex-direction: column !important;
    }

    .controls-container {
        background-color: transparent;
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        min-width: 3rem;
        margin-right: 0 !important;
        box-shadow: none !important;
    }

    .control-icon {
        color: $primaryColor;
    }
}