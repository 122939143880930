@import "../../../globalStyles.scss";

.manage-project-data-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - #{$sideNavControlPanel});
    min-width: calc(100% - #{$sideNavControlPanel});

    .content-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0em 2rem;
        //.jurisdiction-title seems to be pulling from LandingPage.scss

        .jurisdiction-dropdown-wrapper {
            .dropdown-h2o {
                .dropdown-element {
                    width: 300px;
                }
            }
        }

        .sub-header {
            font-size: 1.3em;
            font-weight: 600;
            color: #777777;
            padding: 0.5rem 0 2rem;
        }

        .menu-items {
            padding: 0.5rem 0 0;
            margin-top: 2rem;
            width: 100%;
            // the rest of styling for blue menu tabs found on globalStyles.scss
        }

        .manage-project-data-container {
            width: 100%;

            .page-controls {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .button-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 1.5rem 0 0;

                    .ui.button {
                        margin: 0 !important;
                    }

                    .page-count {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin: 0 3rem;
                    }
                }
            }

            .hr-line {
                height: 1px;
                margin: 1rem 0 0;
                background-color: lightgray;
            }

            .sub-header2 {
                font-size: 1.3em;
                font-weight: 600;
                color: #777777;
                padding-top: 2rem;

                .note {
                    font-size: 0.9rem;
                    font-weight: normal;
                    padding-top: 0.2rem;
                }

                i.icon {
                    color: $primaryColor;
                }
            }

            .sub-header3 {
                font-size: 0.85rem;
                font-weight: 450;
                color: #777777;
                padding-top: 0.1rem;
            }

            .ui.segment {
                padding: 0 1rem 1rem;
            }

            .download-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .spacer-div {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    button {
                        white-space: nowrap;
                        font-family: $fontFamilyMain;
                        font-weight: 600 !important
                    }

                    .delete-icon {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        padding-right: 1rem;
                        font-size: 1.6rem;
                        font-weight: bold;

                        .confirm-delete-modal {
                            i.icon {
                                transition: all .2s ease-in-out !important;
                                -moz-transition: all .2s ease-in-out !important;
                                -webkit-transition: all .2s ease-in-out !important;
                                -o-transition: all .2s ease-in-out !important;
                            }

                            i.icon:hover {
                                color: red !important;
                            }
                        }

                        span {
                            font-size: 1.1rem;
                            color: #747474;
                            padding: 0 0.5rem 0 1rem;
                        }
                    }
                }

                .align-right {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            // this targets the tables inside Upload Projects & Upload Project Data tabs
            table {

                thead,
                tbody {
                    tr {
                        th {
                            .select-clear {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            }
                        }

                        th.center,
                        td.center {
                            text-align: center;
                        }

                        .checkbox {
                            padding-top: 0.2rem;
                            margin: 0 1rem 0;
                        }

                        .trash.icon {
                            height: fit-content;
                            transition: transform 0.2s ease-in-out;
                        }

                        .trash.icon:hover {
                            transform: scale(1.3);
                            color: red !important;
                        }
                    }
                }
            }

            .queue-btn-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-top: 2rem;
                color: $tertiaryColor; // this is tooltip icon
                font-size: 1.8rem; // this is tooltip icon

                .upload-button-and-instructions {
                    padding-right: 1rem;

                    .upload-btn {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-family: $fontFamilyMain;
                        font-size: 1.2rem;
                        font-weight: 600;
                        background-color: $wrampsYellow;
                        color: #4e4e4e;
                        white-space: nowrap;
                        padding: 0.5rem 0.9rem 0.3rem 0.9rem;
                        border-radius: 5px;

                        .upload-icon {
                            font-size: 1.8rem;
                            color: #212121;
                            padding: 1px 0 0 !important;
                            margin: 0 !important;
                        }
                    }

                    .upload-btn:hover {
                        background-color: $tertiaryColor;
                        color: white;
                        cursor: pointer;
                    }
                }

                .upload-tooltip-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding-right: 0.5rem;

                    i.icon.upload-projects-tooltip {
                        font-size: 1.4rem;
                        margin-right: 0.5rem;
                    }

                    span {
                        padding-top: 1px;
                    }
                }


                .ui.button {
                    margin: 0 0.5rem !important;
                }

                .download-btn {
                    color: white;
                    background-color: $primaryColor;
                    margin-right: 1rem !important;
                    border-radius: 5px;
                    padding: 0.6rem 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                }

                .download-btn:hover {
                    background-color: $primaryColorHov;
                }
            }
        }
    }

    i.icon:hover {
        cursor: pointer;
    }

    &__LFD {
        &-container {
            max-width: 1440px;
            display: contents;
            align-items: center;

            &--header {
                align-items: center;
                padding: 1rem 0 0;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;
                /* margin: 0.5rem 0 2rem; */
                color: white;
                text-shadow: 1px 2px #607D8B;
                text-shadow: 3px 2px 1px #4b626e;
                font-size: 3.5em;

                &-container {
                    height: 9em;
                    padding: 1em;
                    margin-bottom: 2.1em;
                    overflow: auto;

                }
            }
        }
    }

    &__shadow {
        box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1), -3px -3px 3px 3px rgba(247, 251, 255, 0.5), 3px 3px 8px 2px rgba(0, 0, 0, 0) inset, -3px -3px 7px 2px rgba(247, 251, 255, 0) inset;
        border: solid 1px rgba(128, 128, 128, 0.4196078431);
    }

    &__fade-in {
        animation: fadeIn 0.71s ease-in-out forwards;
    }

    &__button {
        background-color: $primaryColor !important;
        color: white !important;

        &--circular {
            border-radius: 3em !important;
            height: 4em !important;
            width: 4em;
            box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important;
        }

        &:hover {
            background-color: $primaryColorHov !important;
            color: white !important;
        }
    }
}

.gradient {
    background: linear-gradient(334deg, #f7faff, #187dcd, #faf9fd);
    background: linear-gradient(134deg, rgb(6 147 227 / 89%), rgba(131, 195, 239, 0.61));
    background-size: 150% 100%;
    animation: gradient-animation 8s cubic-bezier(0.27, 0.01, 0.55, 0.81) infinite;
}


.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium {
    font-size: 1.05rem !important;
}