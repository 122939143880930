@import "../../../../globalStyles.scss";

.interested-parties {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - #{$sideNavControlPanel});
    min-width: calc(100% - #{$sideNavControlPanel});

    .content-div {
        .page-subheader {
            color: $wrampsWaterBlue;
            font-size: 1.8rem;
            font-weight: 600;
            margin-top: 1rem;
        }

        .interested-parties-table {
            padding: 2rem 0;

            i.icon {
                transition: transform 0.2s ease-in-out;
            }

            i.icon:hover {
                cursor: pointer;
                color: red;
                transform: scale(1.3);
            }
        }
    }

    .action-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 1.5em;
    }
}