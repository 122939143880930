// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.text-area-component {
    .required {
        margin: 0 !important;
    }

    .basic-text-area-wrapper {
        display: flex;
    }

    .basic-text-area {
        display: block !important;
        color: $primaryColor !important;
        padding: 0.5em 0 0.3em 0 !important;
        font-size: 1rem !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }

    textarea {
        width: 100%;
    }
}