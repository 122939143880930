@import "../../globalStyles.scss";

.user-mgmt-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - #{$navHeightDesktop});
    background-color: white;

    .user-mgmt {
        width: 100%;
        height: 100%;
        padding: 0;

        .page-subheader {
            color: $wrampsWaterBlue;
            font-size: 1.8rem;
            font-weight: 600;
            margin-top: 1rem;
        }

        .user-mgmt-flex {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding-top: 0.5rem;
            flex-wrap: wrap;

            .filterbox {
                margin: 0.5rem;
            }
        }

        .user-content-div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin: 2rem 0;

            .user-table-div {
                margin-bottom: 2rem;
                min-width: 48%;
                max-width: 48%;

                .ui.celled.table tr th {
                    white-space: normal !important;
                }

                .small-font {
                    font-size: 13px;
                    line-height: 13px;
                }

                i.icon {
                    color: $wrampsWaterBlue;
                    transition: transform 0.2s ease-in-out;
                    margin: auto 0 !important;
                }

                i.icon:hover {
                    color: $primaryColor;
                    transform: scale(1.3);
                }
            }
        }

        .int-part-flex {
            // add interested party tab content here
        }
    }
}