@import "../../globalStyles.scss";

.dashboard {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    .ui.segment {
        margin-top: 1rem !important;
        width: 100%;
    }

    .snapshot-timestamp {
        margin: -1.5rem 0 -0.5rem 0;
        padding-right: 0.5rem;
        width: 100%;
        text-align: right;
        font-size: 1.1rem;

        span {
            margin-left: 0.5rem;
        }
    }
}

// remainder of styling found in WmgPublicDashboard.scss

// styling for extra large screens
@media only screen and (min-width: 2200px) {
    .dashboard {
        .snapshot-timestamp {
            font-size: 1.6rem;

            span {
                margin-left: 1rem;
            }
        }
    }
}