// this gives you access to the site wide global styles
@import "../../globalStyles.scss";

.navbar {
    // all styles for this component should be written in here
    font-family: $fontFamilyMain;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    top: 0;
    left: 0;
    min-width: 100%;
    margin-bottom: 0.2rem;
    height: $navHeightDesktop;
    box-shadow: 0 1px 2px 0 lightgray;

    .nav-inner-container {
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0.5rem 1.5rem 0.5rem 0;

        .left-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            // SVG motion logo is inside bars-div
            .bars-div {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 3.9rem;
                cursor: pointer;
                padding: 0;
                margin: -0.3rem 1.3rem 0;

                svg {
                    margin: 0;
                    padding: 0;
                    border-radius: 10px;
                }
            }
        }

 

        //these are navlinks that always show
        .nav-show {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .nav-lang-div,
            .nav-items-div {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin: 0;
                align-items: center;
            }

            .nav-menu-show {
                color: $navbarBlue !important;
                text-transform: uppercase;
                font-weight: 600 !important;
                font-size: 16px !important;
                letter-spacing: 1px;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;
                margin: 0 0.5rem;
                cursor: pointer;
            }

            .active {
                color: $navbarBlueActive !important;
                border-bottom: 3px solid dodgerblue;
            }

            .expand {
                transition: all 0.2s ease-in-out !important;
            }

            .expand:hover {
                transform: scale(1.1) !important;
            }
        }

        // hamburger dropdown icon
        .nav-dropdown {
            .menu-dropdown {
                color: white !important;
                text-transform: uppercase;
                display: flex !important;
                flex-direction: row !important;
                justify-content: flex-end !important;
                padding: 0 !important;
                margin: 0 !important;

                .bars.icon {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    color: $primaryColor;
                    font-size: 2rem;
                    margin: 0 0 0 1rem;
                }
            }

            // start of dropdown menu
            .ui.top.right.pointing.dropdown>.menu {
                overflow: hidden; //prevents hover effects from causing square corners at top/bottom

                .login-logout {
                    padding: 0.5rem;
                }

                .item {
                    color: $navbarBlue;
                    font-weight: 600;
                }

                .active.item,
                .item:hover {
                    background: $gradient3;
                    color: white !important;
                    text-shadow: 1px 1px 1px black;
                }
            }

            // these make menu items have rounded borders since the 'overflow: hidden;' doesnt seem to be working from the parent container above
            #styledMenu .item:first-child,
            #mobileMenu .item:first-child {
                border-radius: 5px 5px 0 0;
            }

            #styledMenu .item:last-child,
            #mobileMenu .item:last-child {
                border-radius: 0 0 5px 5px;
            }
        }
    }
    .left-container-links {
    
        @media only screen and (max-width: 768px) {
            display: none !important;
        }
    }
}


@media only screen and (min-width: 2200px) {
    .navbar {
        .nav-inner-container {
            .nav-show {
                .nav-menu-show {
                    // font-size: 2rem !important;
                    // margin: 0 1.5rem;
                }

                .expand:hover {
                    transform: scale(1.2) !important;
                }
            }
        }
    }
}