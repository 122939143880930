@import "../../../../../globalStyles.scss";


.legend-wrapper {
    position: relative;
    cursor: default;

    .legend {
        font-family: "Poppins", sans-serif;
        position: absolute;
        z-index: 1000000;
        padding: 0.5rem 0.5rem 0.5rem;
        margin: 1.5rem 0 0 -29rem;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 0.3rem;
        width: 28.5rem;
        box-shadow: 2px 2px 4px 0px #7a7a7a;

        &-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-title {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-size: 1.2rem;
                font-weight: 500;
                letter-spacing: 1px;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2rem;
                    font-weight: $fontWeight;
                    background: $wrampsWaterBlue !important;
                    color: white;
                    border-radius: 5px;
                    text-align: center;
                    padding: 0;
                    margin: -1.5rem 0 0 1.2rem;
                    width: 14rem;
                    height: 2.3rem;
                    border: 1.5px solid white;
                    box-shadow: 0.5px 0.5px 2px 0 lightgray;
                }
            }

            &-close {
                margin-right: -0.5rem;

                .close-icon {
                    height: fit-content;
                    stroke: black;
                    stroke-width: 3;
                    font-size: 1.5rem;
                    color: #5c5c5c;
                }
            }

            &-close:hover {
                cursor: pointer;
            }
        }

        &-layers {
            padding: 0 0.5rem;

            &-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 0.1rem 0 0;
                border-bottom: 1px solid lightgray;

                .legend-toggle {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    margin: 0.25rem 0.5rem 0.25rem 0;
                    height: 1.8rem;
                }

                .legend-toggle-label {
                    font-weight: 500;
                    white-space: nowrap;
                    color: #5d5d5d;
                }
            }

            .last-geojson-layer {
                border-bottom: 0;
                margin-bottom: 0.5rem;
            }

            .legend-basemap-layers {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                padding: 0;
                margin: 0 0 0;
                font-size: 1.1rem;
                font-weight: 600;
                color: $primaryColor;

                .dropdown.icon {
                    margin-top: 0.25rem;
                    margin-left: 0.5rem;
                }
            }

            .active.title {
                margin: 0;
            }

            .active.content {
                margin-top: -0.5rem;
            }

            &-item:last-child {
                border-bottom: none;
            }
        }
    }

    .legend-icon {
        position: absolute;
        z-index: 1000;
        padding: 1.25rem 0.5rem 0.15rem;
        margin: 0.5rem 0 0 -5.9rem;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0.5px 0.5px 5px 1.5px #ababab;

        i.icon {
            padding-left: 0.22rem;
            font-size: 3.5rem;
            color: #1680b5;
        }

        .legend-icon-label {
            font-size: 0.9rem;
            font-weight: bolder;
            width: fit-content;
            padding: 0.2rem 0 0 0.45rem;
            margin: -20px 0 0 0;
        }
    }

    .legend-icon:hover {
        cursor: pointer;
    }
}

// LEGEND TOGGLES
.legend-toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.legend-toggle-switch input[type="checkbox"] {
    display: none;
}

.legend-toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}

.legend-toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 0;
    top: -1.5px;
    width: 23px;
    height: 23px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}


// TOGGLE COLORS
// default ****************

.legend-toggle-switch input[type="checkbox"]:checked+.switch::before {
    transform: translateX(18px);
    background-color: #336699;
}

.legend-toggle-switch input[type="checkbox"]:checked+.switch {
    background-color: #6699cc;
}

// end default *****************


// completed wcms
.legend-toggle-switch.completed-wcms input[type="checkbox"]:checked+.switch::before {
    background-color: #0070C0;
    background: url("../../../../../images/completewcm.svg") center center no-repeat;
}

.legend-toggle-switch.completed-wcms input[type="checkbox"]:checked+.switch {
    background-color: #83b5e7;
}

// in progress wcms
.legend-toggle-switch.in-progress-wcms input[type="checkbox"]:checked+.switch::before {
    background-color: #0070C0;
    ;
    background: url("../../../../../images/inprogresswcm.svg") center center no-repeat;
}

.legend-toggle-switch.in-progress-wcms input[type="checkbox"]:checked+.switch {
    background-color: #83b5e7;
}

// new & redev on project details map (preview tab on build watershed form)
.legend-toggle-switch.new-and-redevelopment input[type="checkbox"]:checked+.switch::before {
    background-color: #96E4A2;
    background: url("../../../../../images/newredev.svg") center center no-repeat;
}

.legend-toggle-switch.new-and-redevelopment input[type="checkbox"]:checked+.switch {
    background-color: #96E4A2;
}

// low flow diversions
.legend-toggle-switch.low-flow-diversions input[type="checkbox"]:checked+.switch::before {
    background: url("../../../../../images/LowFlowDiversionCircle.svg") center center no-repeat;
}

.legend-toggle-switch.low-flow-diversions input[type="checkbox"]:checked+.switch {
    background-color: #671653;
}
// Spreading Grounds
.legend-toggle-switch.spreading-grounds input[type="checkbox"]:checked+.switch::before {
    background: url("../../../../../images/SpreadingGroundCircle.svg") center center no-repeat;
}

.legend-toggle-switch.spreading-grounds input[type="checkbox"]:checked+.switch {
    background-color: #920816;
}

// new & redev on manage project data map (preview tab on build watershed form)
.legend-toggle-switch.new-and-redevelopment-projects input[type="checkbox"]:checked+.switch::before {
    background-color: #96E4A2;
    background: url("../../../../../images/newredev2.svg") center center no-repeat;
}

.legend-toggle-switch.new-and-redevelopment-projects input[type="checkbox"]:checked+.switch {
    background-color: #a0efac;
}

//all wcms
.legend-toggle-switch.wcms input[type="checkbox"]:checked+.switch::before {
    background-color: #0070C0;
    ;
    background: url("../../../../../images/inprogresswcm.svg") center center no-repeat;
}

.legend-toggle-switch.wcms input[type="checkbox"]:checked+.switch {
    background-color: #83b5e7;
}

// stormdrains
.legend-toggle-switch.storm-drains input[type="checkbox"]:checked+.switch::before {
    background-color: green;
}

.legend-toggle-switch.storm-drains input[type="checkbox"]:checked+.switch {
    background-color: #1aac1a;
}

// channels
.legend-toggle-switch.channelsdrainage input[type="checkbox"]:checked+.switch::before {
    background-color: #336699;
}

.legend-toggle-switch.channelsdrainage input[type="checkbox"]:checked+.switch {
    background-color: #6699cc;
}

// major watersheds
.legend-toggle-switch.major-watersheds input[type="checkbox"]:checked+.switch::before {
    background-color: #6348ae;
}

.legend-toggle-switch.major-watersheds input[type="checkbox"]:checked+.switch {
    background-color: #927ad2;
}

// subwatersheds
.legend-toggle-switch.subwatersheds input[type="checkbox"]:checked+.switch::before {
    background-color: #a94253;
}

.legend-toggle-switch.subwatersheds input[type="checkbox"]:checked+.switch {
    background-color: #e57575;
}

// wmgs
.legend-toggle-switch.watershed-management-groups-wmgs input[type="checkbox"]:checked+.switch::before {
    background-color: #680068;
}

.legend-toggle-switch.watershed-management-groups-wmgs input[type="checkbox"]:checked+.switch {
    background-color: #8d038d;
}

// jurisdictions
.legend-toggle-switch.jurisdictions input[type="checkbox"]:checked+.switch::before {
    background-color: orange;
}

.legend-toggle-switch.jurisdictions input[type="checkbox"]:checked+.switch {
    background-color: #ffc04c;
}

// safe clean watershed
.legend-toggle-switch.safe-clean-water-program-watershed-areas input[type="checkbox"]:checked+.switch::before {
    background-color: #336699;
}

.legend-toggle-switch.safe-clean-water-program-watershed-areas input[type="checkbox"]:checked+.switch {
    background-color: #6699cc;
}


@media only screen and (max-width: 1166px) {
    .legend-wrapper {
        .legend {
            margin: 1.4rem 0 0 -14.7rem;
            width: 14.2rem;

            &-top {
                &-title {
                    .header {
                        width: 6rem;
                        height: 2rem;
                        margin: -1.7rem 0 0 1.2rem;
                        padding: 0;
                        font-size: 1rem;
                    }
                }

                &-close {
                    margin: -0.35rem -0.5rem 0 0;

                    .close-icon {
                        stroke-width: 2;
                        font-size: 1.2rem;
                    }
                }
            }

            &-layers {
                padding: 0 0.1rem;

                &-item {

                    .legend-toggle {
                        margin: 0rem 0.5rem 0rem 0;
                    }

                    .legend-toggle-label {
                        white-space: normal;
                        letter-spacing: -0.5px;
                        font-size: 0.8rem;
                        line-height: 0.9rem;
                        padding: 0.3rem 0;
                    }
                }

                .last-geojson-layer {
                    margin-bottom: 0;
                }

                .legend-basemap-layers {
                    font-size: 0.9rem;

                    .dropdown.icon {
                        margin-top: 0.35rem;
                        margin-left: 0.3rem;
                    }
                }
            }
        }

        .legend-icon {
            padding: 0.7rem 0.2rem 0;
            margin: 0.5rem 0 0 -4.1rem;

            i.icon {
                font-size: 2.5rem;
            }

            .legend-icon-label {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
                padding: 0 0 0 0.05rem;
                margin: -0.4rem 0 0.2rem 0;
                height: 1rem;
            }
        }
    }

    // whole toggle
    .legend-toggle-switch {
        width: 30px;
        height: 15px;

    }

    // toggle track
    .legend-toggle-switch .switch {
        border-radius: 25px;
    }

    // round switch
    .legend-toggle-switch .switch::before {
        top: -1.3px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }

    // how far switch moves back and forth
    .legend-toggle-switch input[type="checkbox"]:checked+.switch::before {
        transform: translateX(13px);
    }

    // handles image inside round toggle switch
    .legend-toggle-switch.completed-wcms input[type="checkbox"]:checked+.switch::before,
    .legend-toggle-switch.in-progress-wcms input[type="checkbox"]:checked+.switch::before {
        background-size: contain;
    }
}