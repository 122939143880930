@import "../../globalStyles.scss";

.upload-wrapper {
    min-width: 90%;

    .content-wrapper {
        background-color: #ffffff;
        text-align: justify;
        padding: 1.5em 2rem;
        margin: 0 auto 1.5rem;
        max-width: 90%;
        border-radius: 5px;
        box-shadow: $boxShadow1;
        height: auto;

        .pdf-div {
            display: block;
            justify-content: left;

            .pdf-div .upload-ann {
                display: flex;
                justify-content: left;
            }

            .pdf-div-text {
                margin: 0.5rem 0 2rem;
            }

            .upload-ann {
                margin-top: 1rem;
                width: 12%;
            }
        }

        .pdf-list-div {
            display: flex;
            flex-direction: column !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .download-link:hover {
            cursor: pointer;
        }

        .pdf-item {
            display: flex;
            flex-direction: row !important;
            margin: 0.5rem 0;
            min-width: 100%;
        }

        .uploaded-doc-div {
            padding-top: 1.5rem;
        }
    }
}