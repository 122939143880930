@import "../../globalStyles.scss";

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - #{$navHeightDesktop});
    max-height: calc(100vh - #{$navHeightDesktop});
    min-width: calc(100% - #{$sideNavControlPanel});
    max-width: calc(100% - 1.6rem);
    overflow-y: scroll;
    overflow-x: hidden;

    .alert-msg-div {
        padding: 5em 3em 0 3em;
    }

    // styles for child components
    .jurisdiction-title {
        font-size: 1.5em;
        font-weight: 700;
        color: $primaryColor;
    }

    .jurisdiction-div {
        width: 40%;
    }
}

@media screen and (max-width: 700px) {
    .landing-page {
        max-width: 100%;
    }
}