.capture-config-tab {
    max-width: 65em;
    margin: 0 auto;

    &__info-text-container {
        text-align: left;
        margin: 1em 0 2em;

    }

    &__toggle-text {
        color: #1ec11e;
        font-size: 1.2em;
        font-weight: 800;

        &--enabled {
            color: #1ec11e;
        }

        &--disabled {
            color: #7e7878;
        }
    }

    &__toggle-container {
        box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1), -3px -3px 3px 3px rgba(247, 251, 255, 0.5), 3px 3px 8px 2px rgba(0, 0, 0, 0) inset, -3px -3px 7px 2px rgba(247, 251, 255, 0) inset;

    }
}