.auth-login-component {
    .header-style {
        display: 'flex';
        background-color: '#26c6da';
        justify-content: 'space-between';
        padding: 10;
    }

    .link-style {
        color: 'white';
        text-decoration: 'underline';
    }
}