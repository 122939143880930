// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

// By nesting the styles inside the wrapper container you can prevent styles from bleeding through to other components on accident

.reports-sidebar::-webkit-scrollbar {
    /* Hides scrollbar for Chrome, Safari & Opera */
    display: none;
}

.reports-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - #{$navHeightDesktop});
    max-height: calc(100vh - #{$navHeightDesktop});
    font-family: $fontFamilyMain;
    background-color: white;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow-x: none;

    .alert-message-page-div {
        font-size: .7em !important;
        padding: 1em;
    }

    .content-container::-webkit-scrollbar {
        /* Hides scrollbar for Chrome, Safari & Opera */
        display: none;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 0;
        min-width: 100%;

        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #005f92;
            font-family: Arial;
            font-size: 2rem;
            font-weight: 700;
            line-height: 2rem;
            padding: 15px 0px;
            margin: 0;
            min-width: 100%;
            min-height: 7rem;
            text-align: center;
            text-shadow: $navbarTextShadow;
        }

        .card-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 1rem 0 2rem !important;

            .wave-div2 {
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                padding: 0;
                margin: 0;
            }

            .inner-width-container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 1.75em;
                line-height: 1.3em;
                background-color: $waveColor;
                padding: 1rem 0 1.5rem;
            }
        }
    }

    .dropdown-h2o .dropdown-title {
        font-size: 1.3rem !important;
    }

    .ui.selection.dropdown {
        min-width: 100% !important;
    }

    .smaller-text {
        font-size: 0.7em;
    }

    .card-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: #6a6b72;
        padding: 0.5em;
        margin: 0.5em 0;
        min-width: 260px;
        max-width: 380px;
        justify-content: center;
        align-content: center;
        text-align: center;
        background: $gradient2;
        cursor: pointer;
        white-space: nowrap;
        border: 1px solid white;
        border-radius: 30px;
        box-shadow: $boxShadow1;
    }

    .card-div:hover,
    .active-custom {
        color: white;
        text-shadow: 1px 1px 1px black;
        background: $gradient3;
    }

    .active-custom {
        font-size: 1.2rem;
        box-shadow: $boxShadow2;
        border: 1.5px solid white;
        width: 280px;
        color: white;
    }

    .disabled {
        color: #aeaeae;
    }

    .disabled .overlay {
        display: none;
    }

    .disabled:hover .not-overlay {
        display: none;
    }

    .disabled:hover .overlay {
        color: white;
        display: inline;
    }

    .disabled.active-custom {
        color: white !important;
    }
}