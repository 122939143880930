@import "../../globalStyles.scss";

.dropdown-h2o {
    .dropdown-title {
        text-align: left;
        font-size: 1rem;
        font-weight: 700;
        color: $primaryColor !important;
        padding: 0.5rem 0 0.2rem !important;
    }

    .dropdown-h2o i {
        color: $primaryColor !important;
    }

    .dropdown-h2o a {
        color: white !important;
        background-color: $wrampsWaterBlue !important;
    }

    .dropdown-h2o .delete {
        color: white !important;
    }

    .label {
        background-color: $wrampsWaterBlue;
        color: white !important;

        .delete {
            opacity: .8 !important;
        }
    }

    .label:hover {
        background-color: $wrampsWaterBlueHov !important;
        color: white !important;
    }
}

//these 2 styles target the color of the selected option
.ui.menu .ui.dropdown .menu>.active.item {
    color: $wrampsWaterBlue !important;
}

.ui.dropdown .menu .selected.item {
    color: $wrampsWaterBlue !important;
}

//dropdown box styling for regular
.ui.selection.dropdown.dropdown-element {
    width: 17rem;
    padding-left: 0.7rem;
}

.ui.search.selection.dropdown>input.search {
    padding-left: 0.7rem; // fixed cursor not being aligned with content
}

//dropdown option choices
.ui.selection.dropdown .menu>.item {
    padding: 0.7rem !important;
}

//starts dropdown box styling for slim
.ui.selection.dropdown.dropdown-element-slim {
    padding: 0 0 0 0.5rem;
    width: 16.5rem;
    min-height: 0 !important;
    height: 25px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-element-slim>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
    align-self: flex-start !important;
    font-size: 1.3rem !important;
    padding: 1px 2px 0 0 !important;
}

.dropdown-element-slim>.text {
    font-size: 1rem !important;
}

// spinning circle while loading (outer)
.ui.loading.dropdown.dropdown-element-slim>i.icon:before {
    top: 0.72em;
    left: 2.15em;
    width: 20px !important;
    height: 20px !important;
}

// spinning circle while loading (inner)
.ui.loading.dropdown.dropdown-element-slim>i.icon:after {
    top: 0.72em;
    left: 2.15em;
    width: 20px !important;
    height: 20px !important;
}

//ends dropdown box styling for slim