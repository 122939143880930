@import "../../globalStyles.scss";

.help-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: calc(100vh - #{$navHeightDesktop});

    .help-page {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: white;
        margin: 0 5rem;

        .page-header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .hr-line {
            height: 1px;
            margin: 2rem 0;
            background-color: lightgray;
            width: 500px;
        }

        .help-section1,
        .help-section2,
        .help-section3 {
            margin: 0 8rem;
            font-size: 1.6rem;
            text-align: left;

            .section-title {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .section-text {
                display: flex;
                flex-direction: row;
                font-size: 1.3rem;
                padding-bottom: 0.8rem;

                .indent {
                    padding-left: -26px;
                }

                a:hover {
                    color: $secondaryColor;
                }
            }

            .blue {
                color: $primaryColor;
                padding-top: 1px;
                margin-right: 1rem;
            }

            .yellow {
                color: $tertiaryColor;
            }
        }
    }

    .wave-div {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        align-items: center;
        background-color: white;
        padding: 0;
        margin: 0;
        width: 100%;

        img {
            width: 100%;
        }
    }
}