// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

// By nesting the styles inside the wrapper container(.Sample) you can prevent styles from bleeding through to other components on accident
.addon-input-component {
    .ui.input {
        min-width: 100% !important;
    }

    .required {
        margin: 0 !important;
    }

    .addon-input-label-wrapper {
        display: flex;
    }

    .addon-input-label {
        display: block !important;
        color: $primaryColor !important;
        padding: .5em 0 .3em 0 !important;
        font-size: .92857143em !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }

    .addon-div {
        .primary {
            color: white !important;
            background-color: $primaryColor !important;
        }

        .secondary {
            color: $tertiaryColor !important;
            background-color: $secondaryColor !important;
        }

        .tertiary {
            color: white !important;
            background-color: $tertiaryColor !important;
        }
    }
}