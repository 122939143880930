@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

// ----- Start text shadow Styles

$whiteTextShadow: 0px 2px 2px #ffffff !important;
$greyTextShadow: 1px 1.5px 1px #d9d9d9 !important;
$lightTextShadow: 0px 1px 1px #64c2de !important;
$lightWideTextShadow: 1px 1px 1px #ffffff;
$darkTextShadow: 0px 1px 1px #15396a !important;
$navbarTextShadow: 1px 1px 1px #c2c2c2 !important;
$blackTextShadow: 1.5px 1.5px 1px black !important;
$medGreyTextShadow: 1px 1px 1px #a2a2a2 !important;
$accordionTextShadow: 0.5px 0.5px 1px #b5b5b5 !important;
$mapHoverBg: linear-gradient(to bottom, #0776b6, #61b0dd);

// ----- End text shadow  Styles

// ----- Start Box Shadow Styles

$boxShadow1: 1px 1px 2px 0px #afafaf;
$boxShadow2: 1px 1.5px 3px 0px #8f8f8f;
$boxShadow3: 1px 1px 2px 0px #686868;
$boxShadow4: 1px 1px 4px 0px #bbbbbb;
$boxShadow5: 0.5px 0.5px 5px 0px #e0e0e0;
$boxShadow6: 0.5px 0.5px 2px 0px #cfcfcf;

// ----- End Box Shadow Styles

// ----- Start Base colors -----

$whiteColor: white;
$lightgrey: #cacbcd;
$mediumgrey: #b1b1b1;
$darkgrey: #b0b2b5;
$bggrey: #f5f6f7;
$waveColor: #e2ecf5;

$wrampsGrey: #e8e8e8;
$wrampsWaterBlue: #1588c6;
$wrampsYellow: #ffcc33; // not as bold as tertiary
$primaryColor: #006ba5;
$secondaryColor: #64c2de; // secondary
$tertiaryColor: #fbbd08; // tertiary possibly use this #fcd14f

$wrampsWaterBlueHov: #126fa1;
$primaryColorHov: #15396a; // primary1a4682
$secondaryColorHov: #42b4d7; // secondary
$tertiaryColorHov: #eaae00; // tertiary
$lightgreyHov: #dddcdc;
$mediumgreyHov: #818181;

$dangerRed: #db2828; // for alerts and errors
$navbarBlue: #2697d3;
$navbarBlueActive: #2375a1;
$arrowBlue: #e0f1fa;
$greenHighlight: #00d500;
$grayedOut: #bfbfbf;
$sidebarCategory: #178bca;
$capturegrayblue: #547daa;

$gradient1: linear-gradient(to bottom, #3d8ec4, #79b8da); // tabs
$gradient2: linear-gradient(to bottom, #ebebeb, #fafafa); // sidebar white buttons
$gradient3: linear-gradient(to bottom, #0776b6, #61b0dd) !important; //active sidebar buttons & navbar dropdown

// ----- End Base colors -----

$navHeightDesktop: 4.6rem;
$sideNavControlPanel: 2.3em;
$pageHeightDesktop: calc(100vh - 64px); // might be too large for not full screen pages?
$pppSidebarHeight: calc(100vh - 22.7rem);

body ::-webkit-scrollbar {
    width: 10px;
    height: 0;
}

body {
    cursor: default;
}

// ----- Start Font -----

$fontWeight: 600 !important;
$fontFamilyMain: "Poppins", sans-serif;

$fontFamilySecondary: "Helvetica Neue", Helvetica, Arial, sans-serif; // we should find a new font -KR
$smallText: 1em;
$medText: 1.5em;
$largeText: 2em;

// ----- End Font -----

.text-danger {
    color: $dangerRed !important;
    font-size: 1.1em;
}

.ui.visible.left.overlay.sidebar {
    padding: 0 !important;
    margin: 0 !important;
    scrollbar-width: none !important;
}

.ui.visible.left.overlay.sidebar::-webkit-scrollbar {
    width: 0px !important;
}

.ui.left.overlay.sidebar {
    scrollbar-width: none;
}

.ui.left.overlay.sidebar::-webkit-scrollbar {
    width: 0px !important;
}

// ----- Start Headers -----

.page-header {
    color: $primaryColor !important;
    text-shadow: $navbarTextShadow;
    font-weight: $fontWeight;
    font-family: $fontFamilyMain !important;
    font-size: 2em !important;
    padding: 1em 0 0.5em 0;
    letter-spacing: 1px;
    line-height: 2rem;
}

// ----- End Headers -----

// ----- Start Button Styles -----

//wramps2
.wramps-water-blue-btn {
    background-repeat: repeat-x !important;
    background-image: linear-gradient(to bottom,
            rgb(25, 158, 230) 0,
            rgb(23, 139, 202) 100%) !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    border: 1px solid rgb(23, 139, 202) !important;
    background-color: transparent !important;
}

.wramps-water-blue-btn:hover {
    background-image: linear-gradient(to bottom,
            rgb(23, 139, 202) 0,
            rgb(24, 73, 104) 100%) !important;
    border: 1px solid rgb(24, 73, 104) !important;
    cursor: pointer !important;
}

.wramps-water-blue-disabled-btn .ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
    cursor: default;
    background-image: linear-gradient(to bottom,
            rgb(25, 158, 230) 0,
            rgb(23, 139, 202) 100%) !important;
    opacity: 0.45 !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    pointer-events: none !important;
}

.primary-btn {
    background-repeat: repeat-x !important;
    background-image: linear-gradient(to bottom,
            rgb(34, 91, 170) 0,
            rgb(26, 70, 130) 100%) !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    border: 1px solid rgb(26, 70, 130) !important;
    background-color: transparent !important;
}

.primary-btn:hover {
    background-image: linear-gradient(to bottom,
            rgb(26, 70, 130) 0,
            rgb(24, 73, 104) 100%) !important;
    border: 1px solid rgb(24, 73, 104) !important;
    cursor: pointer !important;
}

.primary-disabled-btn .ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
    cursor: default;
    background-image: linear-gradient(to bottom,
            rgb(34, 91, 170) 0,
            rgb(26, 70, 130) 100%) !important;
    opacity: 0.45 !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    pointer-events: none !important;
}

.secondary-btn {
    background-repeat: repeat-x !important;
    background-image: linear-gradient(to bottom,
            rgb(129, 205, 228) 0,
            rgb(100, 194, 222) 100%) !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    border: 1px solid rgb(100, 194, 222) !important;
    background-color: transparent !important;
}

.secondary-btn:hover {
    background-image: linear-gradient(to bottom,
            rgb(100, 194, 222) 0,
            rgb(24, 73, 104) 100%) !important;
    border: 1px solid rgb(24, 73, 104) !important;
    cursor: pointer !important;
}

.primary-disabled-btn .ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
    cursor: default;
    background-image: linear-gradient(to bottom,
            rgb(129, 205, 228) 0,
            rgb(100, 194, 222) 100%) !important;
    opacity: 0.45 !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    pointer-events: none !important;
}

.rounded-btn {
    border-radius: 9rem !important;
    padding: 1em !important;
    font-size: 15px !important;
}

.teal-btn {
    background-repeat: repeat-x !important;
    /* first color should be 3 shades lighter, second color should be original color */
    background-image: linear-gradient(to bottom,
            rgb(0, 204, 194) 0,
            rgb(0, 181, 173) 100%) !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    /*border should be one shade darker than the orginial */
    border: 1px solid rgb(0, 153, 145) !important;
    background-color: transparent !important;
}

.teal-btn:hover {
    /* first color here should be original color, second color should be on shade darker and/or the border color from above */
    background-image: linear-gradient(to bottom,
            rgb(0, 181, 173) 0,
            rgb(0, 153, 145) 100%) !important;
    /* this should be two shades darker than the original color */
    border: 1px solid rgb(0, 128, 121) !important;
    cursor: pointer !important;
}

// ----- End Button Styles -----

// Save helper styles
.save-helper-header {
    color: $tertiaryColor !important;
    font-size: 1.5em !important;
    padding: 0.5rem 1.5rem 1.25rem 1.7rem !important;
}

.save-helper-body {
    font-size: 1.1em !important;
}

.save-helper-btn-save {
    background-color: $primaryColor !important;
    color: white !important;
}

.save-helper-btn-save:hover {
    background-color: $primaryColorHov !important;
    color: white !important;
}

// ----- Start Hov rule -----
.hov-cursor:hover {
    cursor: pointer !important;
}

// ----- End Hov rule -----

.table-loader-tertiary {
    color: $tertiaryColor !important;
}

//gantt lightbox overrides
.gantt_save_btn_set {
    background: $primaryColor !important;
}

.gantt_save_btn {
    visibility: hidden !important;
    width: 5px !important;
}

.gantt_cancel_btn_set {
    background: #5a5a5a !important;
    color: white !important;
}

.gantt_cancel_btn {
    visibility: hidden !important;
    width: 5px !important;
}

.gantt_delete_btn_set {
    background: #c95353 !important;
    color: white !important;
}

.gantt_delete_btn {
    visibility: hidden !important;
    width: 5px !important;
}

.gantt_cal_ltext {
    padding-bottom: 1em !important;
}

.aspect-ratio-box-100 {
    position: relative;
    padding-top: 100%;
    width: 100%;
}

.absolute-top-left {
    top: 0px;
    left: 0px;
    position: absolute;
}

.z-index-minus-one {
    z-index: -1;
}

.display-none {
    display: none;
}

.visibility-hidden {
    visibility: hidden;
}

.opacity-2 {
    filter: opacity(0.2);
}

// ----- Start component styles -----
.content-div {
    display: flex;
    flex-direction: column;
    width: 95%;
}

// ----- End componet styles -----

// ----- Start Dark Table Styles -----

table {
    color: white !important;
    font-weight: 600 !important;
    font-size: 1rem !important;

    // overwrite toggle labels to be white when inside of a table
    .ui.toggle.checkbox input:checked~label {
        color: white !important;
    }

    label {
        color: white !important;
    }

    .ui.toggle.checkbox .box:before,
    .ui.toggle.checkbox label:before {
        background: rgba(241, 241, 241, 0.534) !important;
    }

    .ui.form .disabled.field,
    .ui.form .disabled.fields .field,
    .ui.form .field :disabled {
        pointer-events: none;
        opacity: 0.75 !important;
    }

    .click-to-filter {
        padding: 0px 12px;
        color: #003f47 !important;

        &.dark {
            color: #eee;
        }
    }

    tbody {
        color: $primaryColor !important;

        &>tr>td {
            color: $primaryColor !important;
        }

        &>tr:nth-child(2n) {
            background-color: #f0f0f0 !important;
        }
    }

    &>thead>tr>th {
        background: $wrampsWaterBlue !important;
        color: white !important;
        border: 1px solid $wrampsWaterBlue !important;

        &.sorted {
            background: #5aadb4 !important;
        }
    }
}

// ----- End Dark Table Styles -----

// ----- Start Semantic Card Styles

.ui.centered.cards {

    // light cards
    .project-list-tiles {
        background: #5aadb462 !important;
    }

    .reporting-elements-tiles {
        .background-image {
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;
            background-repeat: no-repeat;
        }
    }

    .content {
        padding: 0 !important;
    }

    .header-tooltip-wrapper {
        max-width: 25em;
    }

    .header-tooltip {
        display: none;
    }

    .header-tooltip-wrapper:hover>.header-tooltip {
        display: block !important;
        pointer-events: none;
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - 245px);
        text-shadow: none;
        font-size: 15px;
        /* visibility: hidden; */
        width: fit-content;
        background-color: #fff;
        color: #3c909b !important;
        text-align: center;
        font-weight: 600;
        border-radius: 0.25rem;
        padding: 5px 8px;
        /* Position the tooltip */
        position: absolute;
        z-index: 999 !important;
    }

    .header-tooltip-wrapper:hover>.header-tooltip::after {
        content: "";
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
    }

    .card-header {
        font-size: 1.7em !important;
        font-weight: 600 !important;
        font-family: $fontFamilySecondary !important;
        padding-bottom: 0.2em;

        /* for overflow ellipsis  */
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;

        &.disabled {
            font-style: italic;
            color: gray !important;
        }
    }

    .card-list-header {
        color: white !important;
        font-size: 1.25em !important;
        font-weight: 700;
        letter-spacing: 0.5px;
        white-space: normal;
        pointer-events: none;
        text-shadow: 1px 0 rgba(0, 0, 0, 0.507), 0 1px rgba(0, 0, 0, 0.507),
            -1px 0 rgba(0, 0, 0, 0.507), 0 -1px rgba(0, 0, 0, 0.507);

        &.disabled {
            font-style: italic;
            color: gray !important;
        }
    }

    .background.video.wrapper {
        position: absolute;
        border-radius: 0.28571429rem !important;
        height: 100%;
        width: 100%;
        max-height: 100%;
        z-index: -1;
    }

    .ui.card .meta,
    .ui.cards>.card .meta {
        margin-bottom: 10px !important;
    }

    .generic-popup-wave {
        width: 100%;
        user-select: none;
    }
}

// ----- End Semantic Card Styles

//  ------ Semantic Overrides

.custom-check-box {
    label {
        font-size: 1.3em !important;
    }

    // the box itself
    .ui.checkbox input:checked~.box:before,
    .ui.checkbox input:checked~label:before {
        background-color: $primaryColor !important;
        border: none !important;
        width: 20px !important;
        height: 20px !important;
    }

    // the box itself on checked HOVER
    .ui.checkbox input:checked~.box:hover::before,
    .ui.checkbox input:checked~label:hover::before {
        background-color: $primaryColor !important;
        border: none !important;
        transform: scale(1.2);
    }

    // the box itself on unchecked HOVER
    .ui.checkbox .box:before,
    .ui.checkbox label:hover::before {
        transform: scale(1.2);
    }

    // the check on checked
    .ui.checkbox input:checked~.box:after,
    .ui.checkbox input:checked~label:after {
        color: white !important;
        width: 20px !important;
        height: 20px !important;
        font-size: 16px !important;
        top: 2px !important;
    }

    // the checkmark on HOVER
    .ui.checkbox input:checked~.box:hover::after,
    .ui.checkbox input:checked~label:hover::after {
        color: white !important;
        transform: scale(1.2);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// ------ Start Sidebar styles -----
.generic-sidebar-wrapper {
    .generic-sidebar {
        width: 340px;
        background-color: #f3f4f5 !important;
        text-align: left !important;
        box-shadow: 2px 0 5px 0 #bdbdbd !important;
        min-height: calc(100vh - #{$navHeightDesktop});
    }
}

// ------ End Sidebar styles -----

// Start of blue menu tabs found throughout site
.ui.secondary.pointing.menu {
    flex-wrap: wrap;

    .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $primaryColor !important;
        background-color: transparent !important;
        border: 1px solid transparent !important;
        border-bottom: 3px solid transparent !important;
        box-shadow: none !important;
        font-weight: 700 !important;
        font-size: 1rem !important;
        min-width: 100px !important;
        padding: 1rem 0.8rem 0.8rem !important;
        margin: 3px 2px -2px 0 !important;
    }

    // active item and hover are the same
    .active.item,
    .item:hover {
        border-bottom: 3px solid $wrampsWaterBlue !important;
        border-color: $wrampsWaterBlue !important;
        background: $gradient1;
        border-radius: 12px 12px 0 0 !important;
        color: white !important;
        text-shadow: 1px 1px 1px black;
        box-shadow: 1px 0px 2px 0px darkgray !important;
    }

    .tab-spacer {
        width: 20px;
    }

    // this is specific to report tab only (Build Watershed Form)
    .report-tab {
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: none !important;
        border-bottom: 0px;

        .item {
            color: $primaryColor !important;
            background-color: transparent !important;
            white-space: nowrap;
            border-radius: 12px 12px 0 0 !important;
            border: 1px solid #e2e2e2 !important;
            border-bottom: 3px solid transparent !important;
            box-shadow: none !important;
            font-weight: 700 !important;
            font-size: 1rem !important;
            min-width: 0 !important;
            padding: 1rem 0.8rem 0.8rem !important;
            margin-bottom: -2px !important;
        }

        .active.item,
        .item:hover {
            border-color: $wrampsWaterBlue !important;
            border-bottom: 3px solid $wrampsWaterBlue !important;
            background: $gradient1 !important;
            border-radius: 12px 12px 0 0 !important;
            color: white !important;
            text-shadow: 1px 1px 1px black;
            box-shadow: 1px 0px 2px 0px darkgray !important;
            margin-bottom: -2px !important;
            padding: 1rem 0.8rem 0.8rem !important;
        }
    }
}

a {
    cursor: pointer;
}

// DASHBOARD STYLING **********************
// Dashboard button groups
.dashboard-button-group {
    &-title {
        font-size: 1rem;
        color: $wrampsWaterBlue;
        padding-left: 0.25rem;
        color: $primaryColor;
        font-weight: 700;
    }

    .ui.buttons {
        margin: 0 1rem 1rem 0;
        border: 0.75px solid white;
        box-shadow: $boxShadow1 !important;
        border-radius: 10px;

        .ui.button {
            color: #6a6b72;
            font-size: 1.1rem;
            font-weight: 600;
            border: 1px solid white;
            background: linear-gradient(to bottom, #d7d7d7, #efefef);
            white-space: nowrap;
            box-shadow: 1px 1px 2px 0px #afafaf !important;
            padding: 0.75rem 1rem;
            min-width: 9rem;
        }

        .ui.button.disabled,
        .ui.button.active.disabled {
            color: #575757;
            font-weight: 600;
            border: 1px solid white;
            background: rgb(188, 188, 188) !important;
            white-space: nowrap;
            box-shadow: 1px 1px 2px 0px #afafaf !important;
            text-shadow: none !important;
        }

        .ui.button:first-child {
            border-radius: 10px 0 0 10px;
        }

        .ui.button:last-child {
            border-radius: 0 10px 10px 0;
        }

        .ui.button.active,
        .ui.button:hover {
            background: $gradient1;
            font-weight: bolder;
            color: white;
            text-shadow: 1px 1px 1px black !important;
        }
    }
}

// media queries for dashboard buttons only - I put the styling in globalStyles because I use them on Welcome Page on last tab also

@media only screen and (min-width: 2200px) {
    .dashboard-button-group {
        &-title {
            font-size: 1.3rem;
            padding-bottom: 0.2rem;
        }

        .ui.buttons {
            margin: 0 1rem 1rem 0;
            border: 0.75px solid white;
            box-shadow: $boxShadow1 !important;
            border-radius: 10px;

            .ui.button {
                font-size: 1.3rem;
                min-width: 11rem;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .dashboard-button-group {
        .ui.buttons {
            .ui.button {
                font-size: 1rem;
                padding: 0.75rem 1rem;
                min-width: unset;
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .dashboard-button-group {
        .ui.buttons {
            .ui.button {
                font-size: 0.8rem;
                padding: 0.5rem;
                white-space: normal;
                min-width: 4.8rem;
                max-width: 5rem;
            }

            .ui.button.no-wrap {
                white-space: nowrap !important;
                min-width: 8rem !important;
                max-width: unset;
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .dashboard-button-group {
        .ui.buttons {
            .ui.button {
                font-size: 0.7rem;
                padding: 0.3rem 0;
                white-space: normal;
                min-width: 3rem;
                max-width: 3.9rem;
            }

            .ui.button.no-wrap {
                padding: 0.3rem 0.5rem;
                font-size: 0.9rem !important;
                white-space: nowrap !important;
                min-width: unset;
                max-width: unset;
            }
        }
    }
}

// end of media queries for dashboard buttons

.subpage-header-1 {
    text-align: center;
    color: #2697d3;
    text-shadow: 1px 1px 1px #c2c2c2 !important;
    font-weight: 700;
    font-size: 1.2em;
    margin: 0 0 1rem;
}

.graph-no-data-wrapper {
    border: 1px dashed #e1e1e1;
    border-radius: 5px;
    min-width: 100%;
}

.graph-no-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.6rem;
    margin: 2rem 0;
    color: gray;
    width: 100%;
    text-align: center;

}

// END DASHBOARD STYLING *********************

// global margin/padding styles
@for $i from 1 through 5 {

    //  this handles margins for all 4 sides
    .m-#{$i} {
        margin: #{$i}em !important;
    }

    //  this handles padding for all 4 sides
    .p-#{$i} {
        padding: #{$i}em !important;
    }

    // this targets mt-1 or mt-5 etc
    .mt-#{$i} {
        margin-top: #{$i}em !important;
    }

    // this targets mt-point1 or mt-point5 etc
    .mt-point#{$i} {
        margin-top: calc($i / 10)+em !important;
    }

    // this targets pt-1 or pt-5 etc
    .pt-#{$i} {
        padding-top: #{$i}em !important;
    }

    // this targets pt-point1 or pt-point5 etc
    .pt-point#{$i} {
        padding-top: calc($i / 10)+em !important;
    }

    .ml-#{$i} {
        margin-left: #{$i}em !important;
    }

    .ml-point#{$i} {
        margin-left: calc($i / 10)+em !important;
    }

    .pl-#{$i} {
        padding-left: #{$i}em !important;
    }

    .pl-point#{$i} {
        padding-left: calc($i / 10)+em !important;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}em !important;
    }

    .mb-point#{$i} {
        margin-bottom: calc($i / 10)+em !important;
    }

    .pb-#{$i} {
        padding-bottom: #{$i}em !important;
    }

    .pb-point#{$i} {
        padding-bottom: calc($i / 10)+em !important;
    }

    .mr-#{$i} {
        margin-right: #{$i}em !important;
    }

    .mr-point#{$i} {
        margin-right: calc($i / 10)+em !important;
    }

    .pr-#{$i} {
        padding-right: #{$i}em !important;
    }

    .pr-point#{$i} {
        padding-right: calc($i / 10)+em !important;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

//  map zoom buttons
.esri-component.esri-zoom {
    border-radius: 5px;
    background-color: $wrampsWaterBlue !important;
}

.esri-widget--button {
    background-color: $wrampsWaterBlue !important;
    color: white !important;
}

.esri-widget--button[title~=in] {
    border-radius: 5px 5px 0 0;
}

.esri-widget--button[title~=out] {
    border-radius: 0 0 5px 5px;
}