    .card {
        min-width: 250px;
        max-height: 260px;
        max-height: 200px;
        min-height: 165px;
        border-radius: 0.5em;
        background-color: #547daa;
        // border: 1px solid #e1e1e1;
        margin-left: 2em;
        margin-right: 2em;
        box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23);
        height: fit-content;

        @media only screen and (max-width: 615px) {
            width: 90%;
            margin: 0 auto 2em;
        }

        .card-content {

            padding: 1.5em !important;

            .card-header {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 0.5em;
            }

            .header-icon {
                font-size: 2.5em;
                display: flex;
                flex-direction: column;
                margin-right: 0.5em;
                opacity: 0.5;
            }

            .header-div {
                display: block;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .header-text {
                    display: block;
                    font-size: 3.1em;
                    // font-weight: bold;
                    color: white;
                    // text-shadow: 2px 2px #3d5e78;
                }

                .lfd-header-text {
                    font-size: 2em;
                    margin-top: 0.61em;
                }

                .info-type {
                    font-size: 0.6em;
                    color: white;
                }
            }

            .card-description {
                display: flex;
                width: 100%;
                justify-content: center;
                height: auto;

                .p {
                    justify-content: center;
                }


                .description-text {
                    font-size: 2em;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    color: #9ce1f5;
                    font-weight: bold;
                    margin: 0.1235em 0;
                    text-shadow: 2px 2px #3d5e78;
                }
            }
        }
    }

    .small-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        border-radius: 0.5em;
        max-height: 120px;
        min-height: 120px;
        // margin-top: 20px;
        background-color: rgb(230, 230, 230);
        border: 1px solid #e1e1e1;

        .small-card-content {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .small-header-icon {
                font-size: 2.5em;
                display: flex;
                flex-direction: column;
                margin-right: 0.5em;
                opacity: 0.5;
            }

            .small-header-div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .small-header-text {
                    display: flex;
                    font-size: 2.5em;
                    font-weight: bold;
                }

                .small-info-type {
                    font-size: 0.6em;
                }
            }

        }
    }

    // #547daa

    // #9ce1f5