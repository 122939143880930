// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.pretty-project-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background-color: #f3f4f5;
    padding: 0;
    cursor: default;
    font-family: "Lato", sans-serif;

    .welcome-sidebar-close {
        text-align: right;
        font-size: 1.5rem;
        background-color: transparent;
        height: 1.8rem;
        margin-bottom: -1.8rem;

        i.icon {
            margin: 0.3rem 0.1rem 0.1rem;
            color: $primaryColorHov;
        }
    }

    .header-div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2rem;
        padding: 2rem;
        background-color: #2697d3;
        background-image: url("../../../images/wavy-mosaic.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .side-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 1rem;
            border-radius: 5px;
            border: 0.5px solid white;
            min-height: 10rem;
        }
    }

    .menu-items-ppp {
        padding: 0.5rem 0 0;
        margin: 0 0.35rem 0 0.15rem !important;
        flex-wrap: nowrap !important;
        // the rest of styling for blue menu tabs found on globalStyles.scss
    }

    .body-div {
        display: block;
        text-align: center;

        .overview-text {
            padding: 1.5rem 2rem;
            margin: 2rem 2rem 1.5rem;
            font-size: 1.1rem;
            font-weight: 600;
            line-height: 1.6;
            text-align: left;
            display: inline-block;
            border-radius: 5px;
            background-color: white;
            box-shadow: $boxShadow1;

            .project-title {
                min-width: 218px;
                color: $navbarBlueActive;
                text-align: center;
                text-transform: uppercase;
                margin-top: -0.3rem;
                line-height: 20px;
                font-weight: 600;
            }

            .hr-line {
                height: 1px;
                margin: 0.5rem 0 1rem;
                background-color: lightgray;
            }

            .project-description {
                min-width: 218px;
                color: $sidebarCategory;
                text-align: left;
                line-height: 20px;
                margin-top: 0.3rem;

                span {
                    color: #000000de;
                    margin-left: 0.4rem;
                    font-weight: 400;
                }
            }
        }

        .edit-btn-div-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 1.75rem 0 0.75rem 0;

            .edit-btn-div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 0.3rem 0 0.3rem 0.4rem;
                margin: 0.2rem 0.5rem;
                color: #555555;
                font-size: 1.2rem;
                font-weight: $fontWeight;
                font-weight: normal;
                background-color: $wrampsYellow;
                border-radius: 5px;
                cursor: pointer !important;
                width: 14rem;

                i.icon {
                    font-size: 1rem;
                }
            }
        }
    }

    .header-text {
        padding: 1.5rem 2rem;
        margin: 2rem;
        display: inline-block;
        border-radius: 5px;
        background-color: white;
        box-shadow: $boxShadow1;

        .project-title {
            padding: 0;
            min-width: 218px;
            color: $navbarBlueActive;
            text-align: center;
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: 600;
            line-height: 1.6;
        }
    }

    // overview tab
    .overview-tab-section {
        background-color: white;
        min-height: $pppSidebarHeight;

        .cards-container {
            background-color: $waveColor;
            padding: 0 2rem 1rem;
            font-size: 1.1rem;

            .custom-cards {
                display: flex;
                flex-direction: column;
                align-content: center;
                width: 100%;

                .cards-header-div {
                    display: flex;
                    justify-content: center;

                    .header {
                        padding: 0.3rem 0;
                        font-size: 1.2rem;
                        font-weight: $fontWeight;
                        background: $wrampsWaterBlue !important;
                        color: white;
                        border-radius: 5px;
                        text-align: center;
                        margin-bottom: -1rem;
                        width: 14rem;
                        z-index: 100;
                    }
                }

                .cards-content {
                    background-color: white;
                    border-radius: 5px;
                    padding: 2rem 1.5rem 1.3rem;
                    box-shadow: $boxShadow1;
                    width: 100%;

                    .cards-content-item {
                        display: flex;
                        flex-direction: row;
                        margin-top: 0.3rem;
                        text-align: left;

                        .bold {
                            font-weight: $fontWeight;
                            white-space: nowrap;
                            color: $sidebarCategory;
                            min-width: 8.3rem;
                        }

                        .bold.project {
                            min-width: 9rem;
                        }

                        .key-info-text {
                            padding-left: 0.5rem;
                        }

                        .key-info-text-sg {
                            padding-left: 2.5em;
                        }

                        .project-info-text {
                            padding-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    // capture tab
    .capture-tab-section {
        background-color: white;
        min-height: $pppSidebarHeight;
        padding-top: 1rem;

        .bubble-div {
            background-color: #e2ecf5;
            min-width: 100%;
            min-height: 10em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            padding: 0 0.5rem 0.5rem;

            .bubble-inner-div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: 'Montserrat', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.6;
                text-align: left;
                padding: 0.5rem 1rem;
                margin: 0.3rem 0;

                .water-container {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    min-width: 120px;
                    height: 133px;

                    .coming-soon-overlay {
                        white-space: nowrap;
                        font-family: "Poppins", sans-serif;
                        font-size: 1rem;
                        font-weight: 800;
                        color: black;
                        transform: rotate(-20deg);
                        margin-top: -80px;
                        margin-left: -2px;
                        text-align: center;
                    }
                }

                .text-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 0 0.5rem;
                    max-width: 300px;

                    .div-1 {
                        color: $wrampsWaterBlue;
                        font-size: 1.2rem;
                    }

                    .div-2 {
                        color: #737E80;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .spacer-top {
        margin-top: 2rem;
    }

    // additional info tab
    .additional-text {
        padding: 0;
        margin: 2rem 2rem 0;
        color: #737E80;
        font-family: 'Montserrat', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
        display: inline-block;
        border-radius: 5px;
        background-color: white;
        box-shadow: $boxShadow1;
        min-height: 0 !important;
        max-height: $pppSidebarHeight;

        .overflow {
            border-radius: 5px;
            overflow: hidden !important;
        }

        .additional-chart {
            text-align: left;
            padding: 0.75rem 1rem 1.5rem;
            margin: 0;

            .chart-title {
                text-align: center;

                /* Tooltip container */
                .tooltip {
                    margin-left: 5px;
                    position: relative;
                    display: inline-block;
                    border-bottom: 1px dotted black;
                    /* If you want dots under the hoverable text */
                }

                /* Tooltip text */
                .tooltip .info-icon {
                    color: $wrampsWaterBlue;
                }

                /* Tooltip text */
                .tooltip .tooltiptext {
                    visibility: hidden;
                    width: 230px;
                    background-color: white;
                    background-image: url('../../../images/raindropbg.png');
                    background-size: cover;
                    color: dimgray;
                    text-align: justify;
                    padding: 1rem 1.5rem;
                    box-shadow: $boxShadow1;
                    border-radius: 6px;
                    top: -5px;
                    right: 105%;
                    /* Position the tooltip text */
                    position: absolute;
                    z-index: 1;
                }

                /* Show the tooltip text when you mouse over the tooltip container */
                .tooltip:hover .tooltiptext {
                    visibility: visible;
                }
            }
        }

        .additional-title {
            text-align: left;
            padding: 1.5rem 2rem;
        }

        .justify {
            text-align: justify;
        }

        .small-wave-div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0;
            margin: -0.5rem 0 0; //moves wave up
        }

        .cobenefits {
            width: 100%;
            padding: 0.5rem 0 1.5rem;
            margin: -1rem 0 0;
            line-height: 1.6;
            background-color: $waveColor;
            border-radius: 5px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0.5rem 1rem 0 1.5rem;
            }

            li {
                font-size: 1rem;
                color: $wrampsWaterBlue;
                text-align: left;
            }

            span {
                color: #6a6f77;
            }
        }
    }

    .wave-div {
        min-width: 100%;
        top: 0px;
        z-index: 99;
        position: relative;

        .wave {
            min-width: 100%;
            height: 100%;
            display: block;
        }
    }
}

::-webkit-scrollbar {
    width: 0;
    display: none;
}

.spacer3 {
    height: 3rem;
}

// this styles gray line under blue tabs
@media (max-width: 700px) {
    .pretty-project-sidebar-wrapper {
        .menu-items {
            margin: 0 0.3rem 0 0.25rem !important;
        }
    }
}