@import "../../../../globalStyles.scss";

.public-config-elements {

    .ui.button.active,
    .ui.button:hover {
        background: $gradient1;
        font-weight: bolder;
        color: white;
        text-shadow: 1px 1px 1px black;
    }

    .ui.buttons {
        // For inside button groups only, not single buttons
        margin: 0 1rem 1rem 0;
        border: 0.75px solid white;
        box-shadow: $boxShadow1 !important;
        border-radius: 10px;

        .ui.button {
            border-right: 1px solid white;
        }

        .ui.button:first-child {
            border-left: 0.25px solid white;
            border-radius: 10px 0 0 10px;
        }

        .ui.button:last-child {
            border-right: 0;
            border-radius: 0 10px 10px 0;
        }
    }

    .benefits-group {
        margin: 0.5em 0;

        .ui.button {
            border: 0.25px solid white;
            border-radius: 10px;
        }
    }

    .subpage-container {
        .config-controls {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: 0.5rem;

            .tab-header {
                // styling for both tab headers found in BuildWatershedForm.scss to keep them consistent
            }

            .button-component {
                padding-bottom: 1.5rem;
                white-space: nowrap;
            }
        }

        .ui.accordion.fluid {
            margin-bottom: 1.5rem;

            .content.active {
                // more styling found in BuildWatershedForm.scss

                .config-btn-group {
                    .btn-group-header {
                        margin: 1.5rem 0 0.5rem;
                        font-weight: 600;
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}