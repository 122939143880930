// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

// By nesting the styles inside the wrapper container(.Sample) you can prevent styles from bleeding through to other components on accident
.toggle-component-with-on-click {

    .toggle-container {
        display: flex;
        flex-direction: column;
    }

    .toggle-div {
        display: flex;
        flex-direction: row;
    }

    .top-label-div {
        display: flex;
        flex-direction: row;

        label {
            color: $primaryColor !important;
        }
    }

    .top-label {
        display: block !important;
        color: $primaryColor !important;
        padding: .5em 0 .3em 0 !important;
        font-size: .92857143em !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }

    .hide {
        display: none;
    }

    .padding-top-label-tooltip {
        padding-top: .12em !important;
    }

    .padding-side-label-tooltip {
        padding-top: .3em !important;
    }

    .basic-input-label-wrapper {
        display: flex;
    }

    .basic-input-label {
        display: block !important;
        margin: 0 0 .28571429rem 0 !important;
        color: rgba(0, 0, 0, .87) !important;
        font-size: .92857143em !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }

    .primary {

        .ui.toggle.checkbox input:checked~.box:before,
        .ui.toggle.checkbox input:checked~label:before {
            background-color: $primaryColor !important;
        }

        label {
            color: $primaryColor !important;
        }
    }

    .secondary {

        .ui.toggle.checkbox input:checked~.box:before,
        .ui.toggle.checkbox input:checked~label:before {
            background-color: $wrampsWaterBlue !important;
        }
    }

    .tertiary {

        .ui.toggle.checkbox input:checked~.box:before,
        .ui.toggle.checkbox input:checked~label:before {
            background-color: $secondaryColor !important;
        }
    }

    .ui.toggle.checkbox input:checked~.box,
    .ui.toggle.checkbox input:checked~label {
        color: $primaryColor !important;
        font-size: 1rem;
        font-weight: 700;
    }
}