@import "../../globalStyles.scss";

.downloads {
    .reporting-dropdown-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .label {
            color: $wrampsWaterBlue;
            padding: 10px 10px 0 0;
        }
    }

    .tile-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem 0 0.5rem;
        gap: 1rem;

        .tile1,
        .tile2 {
            flex: 1;
            background-color: white;
            border: 1px solid lightgray;
            border-radius: 10px;
            color: $wrampsWaterBlue;
            font-size: 1.1rem;
            margin: 0;
            padding: 1.5rem 1.5rem 1.3rem;
            align-self: stretch;

            .tile-title {
                color: #787878;
                margin: 0 1rem;
                line-height: 1.2rem;
                text-decoration: underline;
            }

            .tile-description {
                color: #787878;
                font-size: 1.1rem;
                font-weight: normal;
                line-height: 1.4rem;
                text-align: justify;
                margin: 0.5rem 0;
            }

            .tile-download-title {
                color: $primaryColor;
                font-size: 1rem;
                font-weight: 600;
                margin: 1rem 0 0.5rem;
            }

            .tile-downloads {
                font-weight: normal;
                text-align: left;
                margin: 0;
                line-height: 1.8rem;

                span {
                    color: #787878;
                }

                a {
                    font-weight: bold;
                    white-space: nowrap;
                }
            }

            .extracted-coming-soon {
                color: #787878;
                font-weight: normal;
            }
        }
    }
}

@media only screen and (min-width: 2200px) {
    .downloads {
        .reporting-dropdown-wrapper {
            .label {
                padding: 0 1rem 0 0;
                font-size: 1.6rem;
            }

            .dropdown-h2o {
                .dropdown-title {
                    display: none;
                }

                .ui.selection.dropdown.dropdown-element-slim {
                    height: 3rem !important;
                    padding: 0 0 0 1rem;
                    width: 21rem;
                    min-height: 0 !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .menu>.item {
                        font-size: 1.6rem !important;
                        padding: 0.75rem 1rem !important;
                    }
                }

                .dropdown-element-slim>.text {
                    font-size: 1.6rem !important;
                }

                .dropdown-element-slim>.dropdown.icon {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.5rem !important;
                    height: 100%;
                    padding: 0;
                    margin-right: -1rem;
                }
            }
        }

        .tile-wrapper {
            margin: 2rem 0 0.5rem;
            gap: 2rem;

            .tile1,
            .tile2 {
                font-size: 1.6rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .title-description-wrapper {
                    min-height: 11rem;

                    .tile-title {
                        margin: 0.5rem 2rem 1.5rem;
                    }

                    .tile-description {
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        margin: 1rem 1.5rem;
                    }
                }

                .tile-download-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    min-height: 10rem;

                    .tile-download-title {
                        font-size: 1.6rem;
                    }

                    .tile-downloads {
                        line-height: 3rem;

                    }

                    .extracted-coming-soon {
                        height: 4rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .downloads {
        .reporting-dropdown-wrapper {
            flex-direction: column;

            .label {
                color: $wrampsWaterBlue;
                padding: 10px 10px 0 0;
            }
        }

        .tile-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
}