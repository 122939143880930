@import "../../globalStyles.scss";

.pdf-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    padding: 0 !important;
    margin: 0 !important;

    .upload {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.pdf-list-div {
    display: flex;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 !important;
}

.pdf-item {
    display: flex;
    flex-direction: row !important;
    margin: 0.5rem 0;
    min-width: 100%;
}