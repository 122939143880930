@import "../../../globalStyles.scss";

.configure-proj-assumptions-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - #{$navHeightDesktop});
    min-width: calc(100% - #{$sideNavControlPanel});
    max-width: 95%;
    background-color: white;

    .coming-soon {
        margin: 3rem;
        font-size: 2rem;
        font-weight: bold;
    }
}