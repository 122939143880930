// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

// By nesting the styles inside the wrapper container(.Sample) you can prevent styles from bleeding through to other components on accident
.confirm-delete-modal {

    .header,
    .content {
        font-family: $fontFamilyMain !important;
    }

    .trash-icon {
        color: $primaryColor !important;
    }

    .trash-icon:hover {
        color: $primaryColorHov !important;
        cursor: pointer !important;
        transform: scale(1.2) !important;
    }
}