@import "../../../../globalStyles.scss";

.wmg-public-dashboard {
    font-size: 1rem;
    width: 100%;

    .public-element-section {
        text-align: left;
        background-color: white;
        padding: 1rem 1.5rem 1.5rem;
        margin: 1rem 0;
        border-radius: 5px;
        box-shadow: $boxShadow1 !important;

        &-title {
            color: $navbarBlue;
            text-shadow: $accordionTextShadow;
            font-weight: 700;
            font-size: 1.3em;
            padding-bottom: 0.2rem;
            width: fit-content;
            border-bottom: 1.5px solid #e3e3e3;
        }

        &-description {
            padding: 0.5rem 0 0;
        }

        &-content {
            margin: 1rem 0 0;
            padding: 0;

            .program-summary-visual {
                // button group styling found in globalStyles.scss

                &-charts {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;

                    &-pie,
                    &-bar {
                        width: 50%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }

                    &-pie {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem 1rem 1rem 0;

                        .pie-legend-wrapper {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            padding: 0;
                            margin: 0;
                            height: 100% !important;
                            max-width: 100%;

                            .chart-name {
                                text-align: center;
                                width: 100%;
                                font-weight: 600;
                                min-height: 3.5rem;
                                color: $wrampsWaterBlue;
                                margin: 0;
                            }

                            path {
                                cursor: pointer
                            }

                            .program-summary-sunburst-graph {
                                flex: 1;
                                max-width: 75%;
                                height: 100% !important;
                                padding-bottom: 2rem;
                            }

                            .sunburst-legend-wrapper {
                                width: 25%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;

                                .sunburst-legend {
                                    height: fit-content;
                                    border: solid 2px grey;
                                    border-radius: 8px;
                                    padding: 0.25rem 0.5rem 0;
                                    cursor: default;

                                    .legend-item-wrapper {
                                        overflow: hidden;
                                        display: flex;
                                        margin: 5px 0;

                                        .legend-square {
                                            min-width: 1rem;
                                            height: 1rem;
                                            border-radius: 2px;
                                            margin: 0 0 0;
                                        }

                                        span {
                                            margin-left: 5px;
                                            padding-bottom: 2px;
                                            line-height: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &-bar {
                        align-self: stretch;
                        align-content: center;
                        flex-wrap: wrap;
                        padding: 2rem;
                    }
                }
            }

            .program-multibenefits-summary {
                // styling for cobenefit bubble cards found in DashboardBubbles.scss
            }

            .program-progress {
                justify-content: center;
                align-items: center;

                .program-progress-inner-wrapper {

                    .button-group-dropdown-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        .dashboard-button-group {
                            padding: 0 2rem 0.1rem 0;
                        }

                        .dropdown-element-wrapper {
                            margin: 0.05rem 0 1rem 0.15rem;

                            .dropdown-title {
                                text-align: left;
                                font-size: 1rem;
                                font-weight: 700;
                                padding-left: 0.1rem;
                                color: $primaryColor;
                            }

                            .dropdown-element {
                                font-weight: 500;
                                box-shadow: $boxShadow6 !important;
                            }
                        }
                    }

                    .program-progress-graph {
                        border: 1px dashed rgb(225, 225, 225);
                        border-radius: 5px;
                        color: #a5a5a5;
                    }
                }

                // button group styling and no graph message found in globalStyles.scss
            }


            .program-summary-table {

                // button group styling found in globalStyles.scss
                .summary-table {
                    .ui.table {
                        overflow: hidden;

                        thead tr>th {
                            position: sticky !important;
                            line-height: 1rem;
                        }

                        tr>td {
                            text-align: center;
                        }

                        tr>td:hover {
                            cursor: default;
                        }

                        tr:nth-child(even) {
                            background-color: #f9f9f9 !important;
                        }

                        tr.subtotal {
                            background-color: #ebf6ff !important;

                            td.subwatershed {
                                border-left: 1px solid #22242626; // to match semantic ui border color
                            }
                        }

                        tr.total {
                            font-weight: bolder;
                            text-align: center;
                            background-color: #D6EDFF !important;

                            td {
                                font-size: 1.1rem;
                                font-weight: 900 !important;
                                color: #004164 !important;
                            }

                            td:first-child {
                                background-color: white;
                            }
                        }

                        tr.regular {

                            td.project-type {
                                text-align: left;
                                padding: 0.5rem 1.5rem;
                                background-color: white;
                            }

                            td.subwatershed {
                                padding: 0.5rem 1rem;
                                border-left: 1px solid #22242626; // to match semantic ui border color
                            }
                        }
                    }
                }

                // see DashboardSummaryTable.scss for table styling
            }

            .project-details-map {
                padding: 0;
                // most styling done inline on ProjectDetailsMap.tsx
            }

            .project-details-table {
                // styling found in ProjectList.scss
            }

            .additional-info {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    .fadeOut {
        opacity: 0;
        width: 0;
        height: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    }

    .fadeIn {
        opacity: 1;
        width: 100px;
        height: 100px;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    }
}

// styling for extra large screens
@media only screen and (min-width: 2200px) {
    .wmg-public-dashboard {
        .public-element-section {
            padding: 2rem 2rem 2.5rem;
            margin: 2rem 0;

            &-title {
                font-size: 2rem;
                padding-bottom: 0.6rem;
            }

            &-description {
                font-size: 1.5rem;
                padding: 1rem 0
            }

            &-content {

                .program-summary-visual {
                    &-charts {
                        &-pie {
                            .pie-legend-wrapper {
                                .chart-name {
                                    margin: 0.5rem 0 0;
                                    font-size: 1.6rem;
                                }

                                .program-summary-sunburst-graph {
                                    padding-bottom: 3rem;
                                }

                                .sunburst-legend-wrapper {
                                    .sunburst-legend {
                                        .legend-item-wrapper {
                                            padding: 0.2rem 0;

                                            span {
                                                font-size: 1.3rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .program-multibenefits-summary {
                    // styling for cobenefit bubble cards found in DashboardBubbles.scss
                }

                .program-progress {
                    .program-progress-inner-wrapper {

                        .button-group-dropdown-wrapper {
                            margin-bottom: 2rem;

                            .dropdown-element-wrapper {
                                .dropdown-title {
                                    font-size: 1.3rem;
                                    padding-bottom: 0.3rem;
                                }

                                .ui.selection.dropdown.dropdown-element {
                                    width: 30rem;

                                    .text {
                                        font-size: 1.5rem;
                                    }

                                    .menu>.item {
                                        font-size: 1.6rem;
                                        padding: 0.75rem 1rem !important;
                                    }
                                }
                            }
                        }
                    }
                }


                .program-summary-table {
                    .summary-table {
                        padding: 1.5rem 0 0;

                        .ui.table {

                            thead tr>th,
                            tr>td {
                                line-height: 1.4rem;
                                font-size: 1.4rem;
                            }

                            tr.subtotal {
                                td.subwatershed {
                                    font-size: 1.4rem;
                                }
                            }

                            tr.total {
                                td {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1166px) {
    .wmg-public-dashboard {
        .public-element-section {
            padding: 1rem 1rem 1.5rem;

            &-content {
                margin: 1rem 0 0;
                padding: 0;

                .program-summary-visual {
                    &-charts {
                        display: flex;
                        flex-direction: column;

                        &-pie,
                        &-bar {
                            width: 100%;
                        }
                    }
                }

                .program-summary-table {

                    .summary-table {
                        .ui.table {
                            thead tr>th {
                                font-size: 0.8rem;
                                line-height: 0.8rem;
                            }

                            tr,
                            tr.regular,
                            tr.total,
                            tr.subtotal {


                                td,
                                td.project-type,
                                td.subwatershed {
                                    line-height: 0.9rem;
                                    padding: 1rem;
                                    font-size: 0.9rem;
                                }

                                td.project-type {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .wmg-public-dashboard {
        .public-element-section {
            &-content {
                .program-summary-table {

                    .summary-table {
                        .ui.table {
                            thead tr>th {
                                font-size: 0.7rem;
                                line-height: 0.7rem;
                                padding: 0.5rem;
                            }

                            tbody {

                                tr,
                                tr.regular,
                                tr.total,
                                tr.subtotal {

                                    td,
                                    td.project-type,
                                    td.subwatershed {
                                        font-size: 0.7rem;
                                        line-height: 0.7rem;
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .wmg-public-dashboard {
        .public-element-section {
            &-content {
                .program-summary-visual {
                    &-charts {
                        &-pie {
                            padding: 1rem;

                            .pie-legend-wrapper {
                                flex-direction: column;
                                align-items: center;
                                width: 100%;

                                .program-summary-sunburst-graph {
                                    width: 100%;
                                    text-align: center;
                                    min-height: 25rem !important;

                                    .chart-name {
                                        min-height: 2.5rem;
                                    }
                                }

                                .sunburst-legend-wrapper {
                                    width: 100%;
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }

                .program-summary-table {
                    .summary-table {
                        .ui.table {
                            thead tr>th:last-child {
                                border-radius: 0 0 0 0;
                            }

                            thead tr>th.rotate {
                                margin: 0 !important;
                                line-height: 0.6rem;
                                font-size: 0.5rem !important;
                                writing-mode: vertical-rl;
                                transform: scale(-1);
                                padding: 0.5rem;
                            }

                            tbody {

                                tr,
                                tr.regular,
                                tr.total,
                                tr.subtotal {

                                    td,
                                    td.project-type,
                                    td.subwatershed {
                                        font-size: 0.5rem;
                                        line-height: 0.5rem;
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .wmg-public-dashboard {
        .public-element-section {
            padding: 1rem 1rem 1.5rem;

            .program-summary-table {

                .summary-table {
                    .ui.table {
                        thead tr>th.rotate {
                            padding: 0.5rem;
                        }

                        tbody {

                            tr,
                            tr.regular,
                            tr.total {

                                td,
                                td.project-type,
                                td.subwatershed {
                                    font-size: 0.5rem;
                                    line-height: 0.5rem;
                                    padding: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}