// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.welcome-page-sidebar {
    background-color: white;

    .ui.vertical.segment.wide.visible.sidebar {
        background-color: white;
        box-shadow: 2px 0 5px 0 #bdbdbd !important;
        width: 23.4rem !important;
    }
}