// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.button-component {

    .ui.button {
        font-family: $fontFamilyMain;
        font-weight: 600;
        margin: 0 !important;
    }

    .primaryBtn {
        background-color: $primaryColor !important;
        color: white !important;
        font-family: $fontFamilyMain !important;
    }

    .primaryBtn:hover {
        background-color: $primaryColorHov !important;
        color: white !important;
    }

    .secondaryBtn {
        background-color: #4CE0D2 !important;
        color: $primaryColor !important;
    }

    .secondaryBtn:hover {
        background-color: $secondaryColorHov !important;
        color: #00444d !important;
    }

    .tertiaryBtn {
        background-color: $primaryColor !important;
        color: white !important;
    }

    .tertiaryBtn:hover {
        background-color: $primaryColorHov !important;
        color: white !important;
    }

    .yellowBtn,
    .yellowBtn:focus {
        letter-spacing: -0.4px !important;
        font-size: 1.05rem;
        font-weight: 700 !important;
        background-color: #ffcc33;
        color: #4e4e4e;
        white-space: nowrap;
        padding: 0.9rem 1rem 0.7rem !important;
        border-radius: 5px;
    }

    .yellowBtn:hover {
        background-color: $tertiaryColor;
        color: white;
        cursor: pointer;
    }

    .yellowBtn.button.disabled {
        background-image: none !important;
        background-color: gray;
        color: white;
        cursor: default;
    }

    .btnHeightSlim {
        padding: 0.42rem 1rem;
        font-weight: 600;
    }
}