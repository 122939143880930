@import "../../../../../globalStyles.scss";

.dashboard-bubbles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0 0;

    .water-gauge-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0 0.5rem 0;
        background-color: white;
        min-width: 11rem;
        border-radius: 20px;
        box-shadow: $boxShadow2;

        .water-gauge-wrapper {
            min-height: 90px;

            .outside-circle {
                min-height: unset;
                text-align: center;
            }
        }

        .water-gauge-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-weight: 600;
            font-size: 1.1rem;
            line-height: 1.5rem;
            max-width: 8rem;
            min-height: 45px; //this keeps single line labels from making tile shorter
            text-align: center;
            white-space: wrap;
        }
    }
}

// styling for extra large screens
@media only screen and (min-width: 2200px) {
    .dashboard-bubbles {
        gap: 2rem;
        margin: 2rem 0 1rem;

        .water-gauge-container {
            padding: 1.5rem 0 0.5rem 0;
            min-width: 13rem;

            .water-gauge-wrapper {
                min-height: 7rem;
            }

            .water-gauge-text {
                font-size: 1.6rem;
                line-height: 1.5rem;
                max-width: 12rem;
                min-height: 4rem; //this keeps single line labels from making tile shorter
            }
        }
    }
}