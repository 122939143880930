.bc {
    width: 17.5rem;
    height: 17rem;
    padding: 0;
    margin: 0;

    svg {
        width: 260px !important;
        height: 260px !important;
    }
}