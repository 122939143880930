@import "../../../../globalStyles.scss";

.publishing-controls-wrapper {
    .subpage-container {
        .preview-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .preview-header {
                font-size: 1.3rem;
                font-weight: 600;
                text-align: center;
                padding: 0 2.5rem 0.5rem;
                margin-top: -0.75rem;
            }
        }

        .publishing-controls {

            .no-public-sections {
                font-size: 1.2rem;
                font-weight: 600;
                color: red;
                padding: 1rem 2rem 2rem;
            }

            .steps {
                flex: 1;
                border: 1px solid lightgray;
                border-radius: 0.5rem;
                background-color: white;
                overflow: hidden;
                width: fit-content;
                padding: 1rem 2rem;
                margin-bottom: 1rem;
                width: 100%;

                &-header {
                    //also see .subpage-header-1 in globalStyles.scss
                    margin-bottom: 0.75rem;
                }

                &-text {
                    text-align: left;
                    padding: 0;
                }

                .ui.message {
                    padding: 0.6rem 1.7rem;
                    margin: 0;
                    width: fit-content;
                    cursor: default;
                }

                &-note {
                    text-align: left;
                    color: red;
                }

                &-loading-data {
                    text-align: justify;
                    font-weight: 600;
                }

                // Step 1 only
                .on-off-list {
                    text-align: left;

                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        list-style: none;
                        font-size: 1.3rem;
                        font-weight: 800;
                        color: $primaryColor;
                        margin-bottom: 0.25rem;

                        .on-off {
                            width: 2.5rem;
                            margin-right: 1.5rem;
                        }

                        .on {
                            color: $greenHighlight;
                        }

                        .off,
                        .de-emphasize {
                            color: $grayedOut;
                        }
                    }
                }

                // Step 2 only
                .publish-data-button {
                    text-align: left;
                    font-weight: 600;
                    color: $primaryColor;

                    .data-timestamp-button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 1rem 0;

                        span {
                            margin-right: 1rem;
                        }
                    }

                    .data-timestamp {
                        margin: 1rem 0;

                        span {
                            color: black;
                            margin-left: 0.5rem;
                        }
                    }
                }


                // Step 3 only
                .return-text {
                    color: $primaryColor;
                }

                .return-text:hover {
                    color: $primaryColor;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .publishing-selections-wrapper {
                    .publishing-button-wrapper {
                        text-align: left;
                        font-weight: 600;
                        color: $primaryColor;

                        .publish-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-top: 1rem;

                            .button-component {
                                .ui.button {
                                    padding: 0.8rem 1rem;
                                    white-space: nowrap;
                                    font-family: "Poppins", sans-serif;
                                }
                            }

                            .last-saved {
                                margin-left: 1rem;

                                span {
                                    margin-left: 0.5rem;
                                    color: black;
                                }
                            }
                        }
                    }

                    .toggle-component-basic {
                        padding-bottom: 0.65rem;
                        margin: 1.5rem 0 0;
                        font-weight: 600;
                        color: $primaryColor;

                        .public-toggle-wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .field {
                                width: 9.5rem; //prevents text from moving to the right when toggle label changes

                                .ui.form.toggle-container {
                                    .top-label-div {
                                        display: none;
                                    }

                                    .toggle-div>.field>.toggle-wrapper {
                                        min-height: 0;

                                        .ui.checkbox.toggle {
                                            min-width: 0 !important;
                                        }

                                        .toggle-label {
                                            font-size: 1rem;
                                            padding-right: 0.1rem;
                                        }

                                        .public {
                                            color: $greenHighlight;
                                        }

                                        .hidden {
                                            color: $grayedOut;
                                        }
                                    }
                                }
                            }

                            .public-toggle-text {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}