// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.ui.modal.confirm-archive-modal {
    background-color: white !important;
    font-size: 1.3rem;
    font-family: $fontFamilyMain;
    padding: 0;
    border-radius: 10px !important;
    overflow: hidden !important;

    .header {
        font-size: 1.5rem;
        font-weight: 400;
        color: $primaryColor;
        border: none !important;
    }

    .content {
        background-color: white;
        padding: 2rem 2rem 3rem !important;
        font-size: 1.2rem;
    }

    .actions {
        background-color: $waveColor  !important;
        padding: 0 !important;
        margin: 0 !important;
        border-top: none !important;

        .wave-div {
            display: flex;
            flex-direction: row;
            align-content: flex-end;
            background-color: white;
            padding: 0;
            margin-bottom: -1px;
            width: 100% !important;

            img {
                margin-bottom: -2px;
                width: 100% !important;
            }
        }

        .modal-btns {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0 0.5rem 1rem 0;
            font-weight: bold;

            button {
                cursor: pointer;
                margin-right: 0.7rem;
            }

            .confirm-btn {
                color: white;
                font-weight: bolder !important;
                cursor: pointer;
                background-color: $primaryColor;
            }

            .confirm-btn:hover {
                background-color: $primaryColorHov;
            }

            .cancel-btn {
                color: white;
                font-weight: bold;
                cursor: pointer;
                background-color: $mediumgrey;
            }

            .cancel-btn:hover {
                background-color: $mediumgreyHov;
            }
        }
    }

    .trash-icon {
        transition: transform 0.2s ease-in-out;
    }

    .trash-icon:hover {
        cursor: pointer !important;
        transform: scale(1.3);
    }
}