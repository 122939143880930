@import "../../../globalStyles.scss";

.build-ann-report-wrapper {
    display: flex;
    height: calc(100vh - #{$navHeightDesktop});
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .build-ann-report-from-page {
        align-items: center;
        min-width: calc(100% - #{$sideNavControlPanel});
        max-width: 95%;
        background-color: white;
        height: 60%;

        .coming-soon {
            margin: 3rem;
            font-size: 2rem;
            font-weight: bold;
        }

        .dropdown-segment {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 1rem 1rem;
            justify-content: center;
            padding: 1rem 0 0;


            .reportWrap {
                margin: 0 0.5rem;
            }
        }

        .header-segment {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 2rem 0 0;

            .center-div {
                .jurisdiction-title {
                    .span {
                        font-weight: 600;
                    }
                }
            }
        }

        .subpage-segment {
            padding: 2rem 2rem;
            background-color: $bggrey;
            border-radius: 5px;
            height: auto;

            .subpage-container {
                margin: 0;
                padding: 0;

                .subpage-header-container {
                    background-color: #ffffff;
                    text-align: justify;
                    padding: 1.5em 2rem;
                    margin: 0 auto 1.5rem;
                    max-width: 90%;
                    border-radius: 5px;
                    box-shadow: $boxShadow1;

                    .subpage-header-2 {
                        .text {
                            margin: 0.5rem 0 2rem;
                        }

                        .btn-div {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .wave-div {
        background-color: white;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        width: 110%;
        position: relative;

        img {
            width: 100%;
        }
    }
}