/* svg logo animation */
.path-0 {
  animation: pathAnim-0 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0% {
    d: path(
      "M 0,700 C 0,700 0,175 0,175 C 88.18181818181822,143.38277511961724 176.36363636363643,111.76555023923444 262,129 C 347.63636363636357,146.23444976076556 430.72727272727263,212.32057416267943 531,214 C 631.2727272727274,215.67942583732057 748.7272727272727,152.95215311004785 856,146 C 963.2727272727273,139.04784688995215 1060.3636363636365,187.87081339712918 1156,202 C 1251.6363636363635,216.12918660287082 1345.8181818181818,195.56459330143542 1440,175 C 1440,175 1440,700 1440,700 Z"
    );
  }
  25% {
    d: path(
      "M 0,700 C 0,700 0,175 0,175 C 60.95693779904306,155.64114832535887 121.91387559808612,136.2822966507177 236,130 C 350.0861244019139,123.71770334928229 517.3014354066986,130.51196172248802 620,129 C 722.6985645933014,127.48803827751198 760.8803827751195,117.66985645933013 851,127 C 941.1196172248805,136.33014354066987 1083.177033492823,164.8086124401914 1190,176 C 1296.822966507177,187.1913875598086 1368.4114832535884,181.0956937799043 1440,175 C 1440,175 1440,700 1440,700 Z"
    );
  }
  50% {
    d: path(
      "M 0,700 C 0,700 0,175 0,175 C 83.87559808612443,190.74162679425837 167.75119617224885,206.48325358851676 275,192 C 382.24880382775115,177.51674641148324 512.8708133971292,132.80861244019138 604,111 C 695.1291866028708,89.19138755980862 746.7655502392345,90.28229665071771 823,120 C 899.2344497607655,149.7177033492823 1000.0669856459331,208.0622009569378 1107,222 C 1213.933014354067,235.9377990430622 1326.9665071770335,205.4688995215311 1440,175 C 1440,175 1440,700 1440,700 Z"
    );
  }
  75% {
    d: path(
      "M 0,700 C 0,700 0,175 0,175 C 93.75119617224877,179.26794258373207 187.50239234449754,183.53588516746413 273,183 C 358.49760765550246,182.46411483253587 435.7416267942584,177.1244019138756 538,166 C 640.2583732057416,154.8755980861244 767.5311004784689,137.9665071770335 881,146 C 994.4688995215311,154.0334928229665 1094.133971291866,187.00956937799043 1185,196 C 1275.866028708134,204.99043062200957 1357.933014354067,189.99521531100478 1440,175 C 1440,175 1440,700 1440,700 Z"
    );
  }
  100% {
    d: path(
      "M 0,700 C 0,700 0,175 0,175 C 88.18181818181822,143.38277511961724 176.36363636363643,111.76555023923444 262,129 C 347.63636363636357,146.23444976076556 430.72727272727263,212.32057416267943 531,214 C 631.2727272727274,215.67942583732057 748.7272727272727,152.95215311004785 856,146 C 963.2727272727273,139.04784688995215 1060.3636363636365,187.87081339712918 1156,202 C 1251.6363636363635,216.12918660287082 1345.8181818181818,195.56459330143542 1440,175 C 1440,175 1440,700 1440,700 Z"
    );
  }
}

.path-1 {
  animation: pathAnim-1 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0% {
    d: path(
      "M 0,700 C 0,700 0,350 0,350 C 120.57416267942583,354.9952153110048 241.14832535885165,359.99043062200957 335,345 C 428.85167464114835,330.00956937799043 495.98086124401914,295.0334928229665 590,293 C 684.0191387559809,290.9665071770335 804.9282296650719,321.8755980861244 887,323 C 969.0717703349281,324.1244019138756 1012.3062200956938,295.46411483253587 1098,295 C 1183.6937799043062,294.53588516746413 1311.846889952153,322.26794258373207 1440,350 C 1440,350 1440,700 1440,700 Z"
    );
  }
  25% {
    d: path(
      "M 0,700 C 0,700 0,350 0,350 C 109.82775119617227,313.61722488038276 219.65550239234454,277.23444976076553 300,296 C 380.34449760765546,314.76555023923447 431.2057416267942,388.6794258373206 524,395 C 616.7942583732058,401.3205741626794 751.5215311004783,340.04784688995215 856,340 C 960.4784688995217,339.95215311004785 1034.708133971292,401.1291866028708 1127,413 C 1219.291866028708,424.8708133971292 1329.645933014354,387.43540669856463 1440,350 C 1440,350 1440,700 1440,700 Z"
    );
  }
  50% {
    d: path(
      "M 0,700 C 0,700 0,350 0,350 C 100.23923444976077,336.91866028708137 200.47846889952154,323.8373205741627 301,323 C 401.52153110047846,322.1626794258373 502.32535885167465,333.56937799043067 585,348 C 667.6746411483253,362.43062200956933 732.22009569378,379.88516746411483 813,373 C 893.77990430622,366.11483253588517 990.7942583732058,334.8899521531101 1098,327 C 1205.2057416267942,319.1100478468899 1322.6028708133972,334.555023923445 1440,350 C 1440,350 1440,700 1440,700 Z"
    );
  }
  75% {
    d: path(
      "M 0,700 C 0,700 0,350 0,350 C 121.64593301435409,352.9377990430622 243.29186602870817,355.8755980861244 326,377 C 408.70813397129183,398.1244019138756 452.47846889952143,437.4354066985646 551,412 C 649.5215311004786,386.5645933014354 802.7942583732058,296.38277511961724 911,292 C 1019.2057416267942,287.61722488038276 1082.3444976076555,369.03349282296654 1163,393 C 1243.6555023923445,416.96650717703346 1341.8277511961724,383.48325358851673 1440,350 C 1440,350 1440,700 1440,700 Z"
    );
  }
  100% {
    d: path(
      "M 0,700 C 0,700 0,350 0,350 C 120.57416267942583,354.9952153110048 241.14832535885165,359.99043062200957 335,345 C 428.85167464114835,330.00956937799043 495.98086124401914,295.0334928229665 590,293 C 684.0191387559809,290.9665071770335 804.9282296650719,321.8755980861244 887,323 C 969.0717703349281,324.1244019138756 1012.3062200956938,295.46411483253587 1098,295 C 1183.6937799043062,294.53588516746413 1311.846889952153,322.26794258373207 1440,350 C 1440,350 1440,700 1440,700 Z"
    );
  }
}

.path-2 {
  animation: pathAnim-2 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-2 {
  0% {
    d: path(
      "M 0,700 C 0,700 0,525 0,525 C 89.7416267942584,500.3492822966507 179.4832535885168,475.69856459330146 265,481 C 350.5167464114832,486.30143540669854 431.80861244019127,521.555023923445 535,524 C 638.1913875598087,526.444976076555 763.2822966507177,496.0813397129187 879,487 C 994.7177033492823,477.9186602870813 1101.0622009569379,490.11961722488036 1193,500 C 1284.9377990430621,509.88038277511964 1362.468899521531,517.4401913875598 1440,525 C 1440,525 1440,700 1440,700 Z"
    );
  }
  25% {
    d: path(
      "M 0,700 C 0,700 0,525 0,525 C 72.06698564593302,556.3205741626793 144.13397129186603,587.6411483253588 244,587 C 343.86602870813397,586.3588516746412 471.53110047846894,553.755980861244 582,553 C 692.4688995215311,552.244019138756 785.7416267942584,583.334928229665 879,582 C 972.2583732057416,580.665071770335 1065.5023923444976,546.9043062200957 1159,532 C 1252.4976076555024,517.0956937799043 1346.248803827751,521.0478468899521 1440,525 C 1440,525 1440,700 1440,700 Z"
    );
  }
  50% {
    d: path(
      "M 0,700 C 0,700 0,525 0,525 C 116.69856459330143,510.2248803827751 233.39712918660285,495.4497607655502 336,514 C 438.60287081339715,532.5502392344498 527.11004784689,584.4258373205741 610,587 C 692.88995215311,589.5741626794259 770.1626794258373,542.8468899521531 861,517 C 951.8373205741627,491.1531100478469 1056.2392344497607,486.18660287081343 1155,491 C 1253.7607655502393,495.81339712918657 1346.8803827751196,510.4066985645933 1440,525 C 1440,525 1440,700 1440,700 Z"
    );
  }
  75% {
    d: path(
      "M 0,700 C 0,700 0,525 0,525 C 110.89952153110048,557 221.79904306220095,589 326,583 C 430.20095693779905,577 527.7033492822966,533 598,525 C 668.2966507177034,517 711.3875598086124,545 817,534 C 922.6124401913876,523 1090.7464114832535,473 1205,465 C 1319.2535885167465,457 1379.6267942583731,491 1440,525 C 1440,525 1440,700 1440,700 Z"
    );
  }
  100% {
    d: path(
      "M 0,700 C 0,700 0,525 0,525 C 89.7416267942584,500.3492822966507 179.4832535885168,475.69856459330146 265,481 C 350.5167464114832,486.30143540669854 431.80861244019127,521.555023923445 535,524 C 638.1913875598087,526.444976076555 763.2822966507177,496.0813397129187 879,487 C 994.7177033492823,477.9186602870813 1101.0622009569379,490.11961722488036 1193,500 C 1284.9377990430621,509.88038277511964 1362.468899521531,517.4401913875598 1440,525 C 1440,525 1440,700 1440,700 Z"
    );
  }
}
