// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.input-component {
    .ui.input {
        min-width: 100% !important;

        input {
            padding-left: 0.5rem;
        }
    }

    .required {
        margin: 0 !important;
    }

    .basic-input-label-wrapper {
        text-align: left;
    }

    .basic-input-label {
        display: block !important;
        color: $primaryColor !important;
        padding: 0.5rem 0 0.2rem !important;
        font-size: 1rem !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }
}

.ui.input.input-slim {
    margin: 0;
    line-height: 1rem;
    padding: 0;
    background: #fff;
    border-radius: 0.28571429rem;
    min-height: 0 !important;
    height: 25px !important;
}