@import "../../../globalStyles.scss";

.ui.modal.transition.visible.active.tooltip-modal-component {
    border-radius: 15px !important;
    overflow: hidden !important;

    .header {
        font-family: $fontFamilyMain;
        font-weight: normal;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
        border-bottom: none !important;
        background-color: $waveColor !important;
        color: $primaryColor !important;

        .tooltip-modal-title {
            padding: 2rem 0 0 2rem;
        }

        .wave-div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-content: center;
            background-color: white;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }

    .content {
        margin: 0 0 !important;
        padding-right: 2rem;

        .instructions {
            padding: 0 3rem;
            color: #45484d;

            &-header {
                font-size: 1.3rem;
                font-weight: 600;
                color: #000;
            }

            ol li,
            ul li,
            p {
                font-size: 1.2rem;
                margin: 0 0 0.5rem;
                padding-left: 1rem;
            }

            p {
                padding: 1rem 0 0 2rem;
            }

            ul li {
                margin: 0;
            }

            .ui.table {
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                overflow: hidden;

                th,
                td {
                    padding: 10px;
                    text-align: left;
                }

                th {
                    background-color: #f5f5f5;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                tbody tr {
                    background-color: white !important;

                    td {
                        color: #373737 !important;
                        vertical-align: top;
                        border: 0.5px solid lightgray;
                    }

                    td.bold-text {
                        font-size: 1.1rem;
                    }
                }

                tbody tr:hover {
                    background-color: #e0e0e0;
                }
            }
        }
    }

    .wave-div {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        align-items: center;
        background-color: white;
        padding: 0;
        margin: 0;

        img {
            width: 100%;
        }
    }

    .actions {
        width: 100% !important;
        padding: 0 1.5rem 1rem 0 !important;
        margin: 0 !important;
        border-top: none !important;
        overflow: hidden !important;
        background-color: $waveColor;

        .ok-btn {
            color: white;
            background-color: $primaryColor;
        }

        .ok-btn:hover {
            background-color: $primaryColorHov;
        }
    }
}