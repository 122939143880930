@import "../../../globalStyles.scss";

.project-list {
    .ui.celled.fixed.table {
        thead tr th {
            text-align: center;
        }

        .date {
            text-align: center;
        }

        .summary-edit-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .summary,
            .edit {
                padding: 0 0.5rem 0.1rem;
                margin: 0.2rem 0.5rem;
                color: white;
                font-size: 1rem;
                font-weight: 600;
                background-color: $wrampsWaterBlue;
                border-radius: 5px;
                cursor: pointer;
            }

            .no-results {
                text-align: center !important;
                height: 30px;
            }

        }

    }

    .ui.table td.warning,
    .ui.table tr.warning {
        text-align: center;
        border-radius: 0 0 3px 3px;
    }

    .pages {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ui.mini.pagination.menu {
            box-shadow: 0.75px 0.75px 2px 0 lightgray;

            .item {
                font-size: 0.9rem;
                font-weight: 700;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media only screen and (min-width: 2200px) {
    .project-list {
        .ui.celled.fixed.table {

            thead tr th,
            tbody tr td {
                font-size: 1.4rem;
            }

            tbody tr {
                td {
                    .summary-edit-wrapper {

                        .summary,
                        .edit {
                            padding: 0.5rem 1rem;
                            font-size: 1.3rem;
                        }
                    }
                }

                .no-results {
                    height: 6rem;
                    font-size: 1.6rem;
                }
            }
        }

        .pages {
            .ui.mini.pagination.menu {
                .item {
                    font-size: 1.4rem;
                    height: 4rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .project-list {
        .ui.celled.fixed.table {
            font-size: 0.8rem !important;
            line-height: 0.9rem;

            .summary-edit-wrapper {

                .summary,
                .edit {
                    font-size: 0.7rem;
                    font-weight: 400;
                    padding: 0.2rem 0.4rem;
                }
            }
        }

        .pages {
            .ui.mini.pagination.menu {
                min-height: 2rem;

                .item {
                    min-width: 2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 481px) {
    .project-list {
        .ui.celled.fixed.table {
            font-size: 0.6rem !important;

            .summary-edit-wrapper {

                .summary,
                .edit {
                    font-size: 0.6rem;
                    padding: 0.1rem 0.3rem 0.15rem;
                }
            }
        }

        .pages {
            .ui.mini.pagination.menu {
                min-height: 1.5rem;

                .item {
                    font-size: 0.7rem;
                    min-width: 1.8rem;
                }
            }
        }
    }
}