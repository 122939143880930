@import "../../globalStyles.scss";

.fourth-tab {
    display: flex;
    flex-direction: column;
    align-content: center;

    .progress-reporting {
        display: flex;
        flex-direction: column;

        .top-section {
            background-color: white;
            box-shadow: $boxShadow1;
            border-radius: 10px;
            width: 100%;
            padding: 1.5rem 2rem 1rem;

            &-title {
                color: $wrampsWaterBlue;
                font-size: 1.4rem;
                font-weight: bold;
                padding-bottom: 0.5rem;
            }

            &-line {
                height: 1px;
                margin: 0.5rem 0 1rem;
                background-color: lightgray;
            }

            &-subtitle {
                margin: 0.5rem 0 0;
                text-align: justify;
                font-size: 1.2rem;
                font-weight: normal;
                color: unset;
            }

            .city-watershed-wrapper {
                margin: 0;
                padding: 0.5rem 0 1rem;
                width: 100%;

                .cities,
                .managed {
                    display: flex;
                    flex-direction: row;
                    font-size: 1.1rem;
                    font-weight: normal;

                    .cities-title,
                    .managed-title {
                        text-align: left;
                        white-space: nowrap;
                        color: #373737;
                        margin-top: 1rem;
                        background-color: $arrowBlue;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        padding: 0.2rem 0.3rem 0.26rem 0.7rem;
                        border-radius: 3px;
                        min-width: 20rem;
                        max-width: 20rem;
                        max-height: 2rem;
                    }

                    .cities-text,
                    .managed-text {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-align: left;
                        flex-wrap: wrap;
                        width: 70%;
                        padding-left: 1.5rem;
                        margin-top: 1rem;
                    }
                }

                .cities {
                    margin-bottom: 1.5rem;
                }
            }
        }

        .button-group-section {
            width: 100%;
            padding: 1rem 0;
            margin: 0.5rem 0 0;

            .ui.buttons {
                margin: 0;
            }

            .ui.buttons {
                margin: 0 1rem 1rem 0;
                border: 0.75px solid white;
                box-shadow: $boxShadow1 !important;
                border-radius: 10px;

                .ui.button {
                    color: #6a6b72;
                    font-size: 1.1rem;
                    font-weight: 600;
                    border: 1px solid white;
                    background: linear-gradient(to bottom, #d7d7d7, #efefef);
                    white-space: nowrap;
                    box-shadow: 1px 1px 2px 0px #afafaf !important;
                    padding: 0.75rem 1rem;
                    min-width: 9rem;
                }

                .ui.button.disabled,
                .ui.button.active.disabled {
                    color: #575757;
                    font-weight: 600;
                    border: 1px solid white;
                    background: rgb(188, 188, 188) !important;
                    white-space: nowrap;
                    box-shadow: 1px 1px 2px 0px #afafaf !important;
                    text-shadow: none !important;
                }

                .ui.button.active,
                .ui.button:hover {
                    background: $gradient1;
                    font-weight: bolder;
                    color: white;
                    text-shadow: 1px 1px 1px black !important;
                }
            }
        }

        .downloads-dashboard-wrapper {
            margin: 0;
            padding: 0;
            min-height: 18.7rem;
            width: 100%;
        }
    }
}

@media only screen and (min-width: 2200px) {
    .fourth-tab {
        .progress-reporting {
            .top-section {
                &-title {
                    font-size: 2.2rem !important;
                }

                &-subtitle {
                    font-size: 1.7rem;
                    line-height: 2rem;
                }

                .city-watershed-wrapper {

                    .cities,
                    .managed {
                        font-size: 1.6rem;

                        .cities-title,
                        .managed-title {
                            font-size: 1.6rem;
                            padding: 0.75rem 0 0.75rem 0.7rem;
                            min-width: 31rem;
                            max-width: 31rem;
                            max-height: unset;
                        }
                    }
                }
            }

            .button-group-section {
                padding: 1.5rem 0;

                .ui.buttons {

                    .ui.button {
                        font-size: 1.6rem;
                        padding: 1rem 0;
                        min-width: 12rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .fourth-tab {
        .progress-reporting {
            .top-section {
                .city-watershed-wrapper {

                    .cities,
                    .managed {

                        .cities-text,
                        .managed-text {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .fourth-tab {
        .progress-reporting {
            .top-section {
                padding: 1.5rem 2rem 0.5rem;

                .city-watershed-wrapper {

                    .cities,
                    .managed {
                        flex-direction: column;
                        margin-bottom: 0;

                        .cities-text,
                        .managed-text {
                            width: 100%;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .fourth-tab {
        .progress-reporting {
            .top-section {
                padding: 1.5rem 1.2rem 0.5rem;

                &-subtitle {
                    font-size: 1rem !important;
                }

                .city-watershed-wrapper {

                    .cities,
                    .managed {

                        .cities-title,
                        .managed-title {
                            min-width: 18.25rem;
                            max-width: 18.25rem;
                            letter-spacing: -0.5px;
                            padding-left: 0.5rem;
                        }

                        .cities-text,
                        .managed-text {
                            font-size: 1rem;
                            padding: 0 0 0 0.5rem;
                        }
                    }
                }
            }
        }
    }
}