.upload-sg-modal {

    &__input_container {
        border: 1px solid #e5e5e5;
    }

    &__dir-msg-body {
        font-style: italic;
        font-size: 0.94em;
    }

    &__btn-container {
        display: flex;
        justify-content: flex-end;
        // margin-top: 20px;
    }

    input[type=file]::file-selector-button {
        background-color: #96999e;
        color: white;
        border: 0px;
        border: 1px solid #524e4e;
        padding: 10px 15px;
        margin-right: 20px;
        transition: .5s;
        border-radius: 5px;
        font-weight: 700;
    }

    input[type=file]::file-selector-button:hover {
        background-color: #1678c2;
        ;
        border: 0px;
        border: 1px solid #e5e5e5;
    }

    input[type=file]::file-selector {
        box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23);
    }

    .ui.form input[type=file] .ui.selection.dropdown {

        border: 1px solid #9E9E9E;
    }

    .ui.selection.dropdown {
        border: 1px solid #9E9E9E;
    }

    .ui.table thead {
        box-shadow: none;
        text-align: center;
    }

    table tbody > tr > td {
        color: #0a0a0a !important;
        font-weight: normal;
        text-align: center !important;
    }
}