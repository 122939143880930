@import "../../../../globalStyles.scss";

.public-config-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .toggle-description-wrapper {
        padding-right: 1.5rem;
        flex: 1;

        .include-toggle {
            .toggle-component-basic {
                .field {
                    .ui.form.toggle-container {
                        .top-label-div {
                            display: none;
                        }

                        .toggle-div {
                            .field {
                                .toggle-wrapper {
                                    min-height: 0;

                                    .toggle-label {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .instructions {
            font-weight: 600;
            margin: 1rem 0 0.5rem;
            max-width: 94rem;

            p {
                margin: 0.75rem 0 0;
                line-height: 1.2rem;
            }
        }

        .section-description {
            display: flex;
            flex-direction: row;
            align-items: center;

            .text-area-component {
                flex: 1;
                max-width: 94rem;

                .field {
                    .textbox {
                        min-height: 7rem;
                        width: 100%;
                        padding: 0.5rem;
                        border-radius: 5px;
                        resize: vertical;
                        border: 1px solid #c9c9c9;
                    }
                }
            }
        }
    }

    .reset-default-wrapper {
        padding: 1.5rem 1.5rem 0 2rem;

        .button-component {
            min-width: 0 !important;

            .ui.button {
                padding: 0.5rem 0 0.5rem 0.65rem !important;
                min-width: 0 !important;

                i.icon {
                    height: fit-content;
                    width: fit-content;
                }
            }
        }
    }
}