@import "../../globalStyles.scss";

.water-gauge-wrapper {
    overflow: auto;
    display: flex;
    justify-content: center;
    min-height: 130px;

    .outside-circle {
        min-height: 130px;
        text-align: center;

        .metric-small {
            font-size: 1.2rem;
            margin-top: -2.9rem;
        }

        .metric-large {
            font-size: 1.4rem;
            margin-top: -2.9rem;
        }
    }

    .bubble-space {
        padding-top: 1rem;
    }
}