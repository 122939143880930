@import "../../../globalStyles.scss";

.build-watershed-form-page {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: calc(100% - #{$sideNavControlPanel});

    .alert-msg-div {
        padding: 5em 3em 0 3em;
    }

    .upload-div-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ui.segment.top-segment {
        padding: 0 !important;
        margin: 0 !important;

        .main-page-top-segment-container {
            padding: 0;

            .segment-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                flex-wrap: wrap;
                padding: 1rem 0 0;

                .button-segment {
                    margin: 0 1rem 1rem;

                    button {
                        box-shadow: none !important;
                        height: 2.8rem !important;
                    }

                    // Jurisdiction/GroupLead button
                    .custom-btn-group-left,
                    .custom-btn-group-right {
                        color: #6a6b72;
                        font-weight: 600;
                        width: 140px;
                        border: 1px solid white;
                        background: $gradient2;
                        cursor: pointer;
                        white-space: nowrap;
                        box-shadow: $boxShadow1 !important;
                    }

                    .custom-btn-group-left {
                        border-radius: 10px 0 0 10px;
                    }

                    .custom-btn-group-right {
                        border-radius: 0 10px 10px 0;
                    }

                    // active and hover use same style
                    .custom-btn-group-left.btnColor,
                    .custom-btn-group-right.btnColor,
                    .custom-btn-group-left:hover,
                    .custom-btn-group-right:hover {
                        background: $gradient1;
                        font-weight: bolder;
                        color: white;
                        text-shadow: 1px 1px 1px black;
                    }
                }

                .dropdown-segment {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 0;
                    margin: 0 1rem 1rem;

                    .reportWrap {
                        margin: 0 0.5rem;
                    }

                    // hides Jurisdiction dropdown when user clicks on GroupLead button
                    .hidden {
                        visibility: hidden;
                    }
                }
            }

            .header-segment {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 2rem 0 0;

                .left-div {
                    flex: 1;
                }

                .center-div {
                    flex: 1;

                    .jurisdiction-title {
                        // this seems to be pulling style from LandingPage.scss so I didn't change it
                    }

                    .subpage-header-1 {

                        span {
                            font-weight: 600;
                        }

                        .report-button-segment {
                            margin: 0.4rem 1rem 2rem;

                            button {
                                box-shadow: none !important;
                                padding: 0.4rem 0.5rem;
                                font-size: 0.9rem;
                            }

                            .small-btn-group-left,
                            .small-btn-group-right {
                                color: #6a6b72;
                                font-weight: 600;
                                width: 80px;
                                border: 1px solid white;
                                background: $gradient2;
                                white-space: nowrap;
                                box-shadow: $boxShadow1 !important;
                            }

                            .small-btn-group-left {
                                border-radius: 5px 0 0 5px;
                            }

                            .small-btn-group-right {
                                border-radius: 0 5px 5px 0;
                            }

                            .small-btn-group-left:hover,
                            .small-btn-group-right:hover {
                                cursor: default;
                            }

                            .show-cursor:hover {
                                cursor: pointer;
                                background: $gradient1;
                                font-weight: bolder;
                                color: white;
                                text-shadow: 1px 1px 1px black;
                            }

                            // active
                            .small-btn-group-left.btnColor,
                            .small-btn-group-right.btnColor {
                                background: $gradient1;
                                font-weight: bolder;
                                color: white;
                                text-shadow: 1px 1px 1px black;
                            }
                        }
                    }
                }

                .right-div {
                    flex: 1;
                }
            }
        }
    }

    .ui.secondary.pointing.menu {
        margin: 0.5rem 0 !important;
        //blue tab styling found on globalStyles.scss near Line 1004 (update location if it changes)
    }
}

//  start of subPage styling
.subpage-segment {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0 !important;
    margin: 0 0 40px 0 !important;
    min-height: calc(100vh - 400px);

    // this is the gray div inside each tab
    .build-watershed-form-subpage {
        flex: 1;
        padding: 1.5rem 2rem;
        background-color: $bggrey;
        border-radius: 5px;
        height: 100%;

        .subpage-container {

            .subpage-header-container,
            .header-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .subpage-header-1 {
                    text-align: center;
                    color: $navbarBlue;
                    text-shadow: $navbarTextShadow;
                    font-weight: 700;
                    font-size: 1.2em;
                    margin: 0 0 1rem;
                }

                .subpage-header-2 {
                    text-align: justify;
                    padding: 1rem 2rem 1.5rem;
                    margin: 0 auto 1.5rem;
                    background-color: white;
                    min-width: 100%;
                    border-radius: 5px;
                    box-shadow: $boxShadow1;
                }
            }

            .description {
                text-align: left;
                padding: 0 2rem 1rem;
            }

            .section1_1-table-bubbles {
                display: flex;
                flex-direction: column;
                align-items: center;


                .sortable-table-wrapper {
                    margin-top: 1rem;
                    min-width: 75%;
                    max-width: 100rem !important;
                }

                .cobenefit-bubbles {

                    .dashboard-button-group {
                        margin: 3rem 0 2rem;
                    }

                    //  styling for cobenefit cards found in DashboardBubbles.scss
                }
            }

            .bulk-download {
                margin: 1rem 0;
            }

            .input-div {
                // this holds summary section
            }

            .save-btn-div {
                display: flex;
                justify-content: flex-end;
                padding: 0;
            }
        }
    }

    .juris-reporting-outputs,
    .wg-reporting-outputs {
        .subpage-container {
            .subpage-header-container {
                .subpage-header-2 {
                    padding-bottom: 0.5rem;

                    .btn-div {
                        margin: 1rem 0;

                        .submitted {
                            font-weight: bold;
                            padding: 0.5rem 2rem 0 1rem;
                        }
                    }

                    .outputs {
                        color: black;
                        font-weight: bold;

                        .outputs-title {
                            padding: 15px 0 0 20px;
                        }

                        li {
                            font-weight: normal;
                        }
                    }

                    li:hover {
                        // add styling if wanted
                    }
                }
            }
        }
    }

    .wg-reporting-outputs {
        .radio-div {
            display: flex;
            flex-direction: row;
            align-items: center;

            .active-toggle {
                font-weight: 600;

                .toggle-component-with-on-click>label {
                    font-weight: 600;
                }
            }
        }

        .reporting-output-table {
            .icon-cell {
                font-size: 1.2em !important;
                font-weight: 600;
            }

            .icon-cell:hover {
                cursor: pointer;
                font-size: 1.15em !important;
            }
        }

        .table-header-div {
            padding-top: .5em;
            display: flex;
            align-items: center;
            flex-direction: column;

            .subpage-header-outputs {
                color: #2697d3;
                text-shadow: 1px 1px 1px #c2c2c2 !important;
                font-weight: 700;
                font-size: 1.2em;
                margin: 1rem 0 0.25rem;
            }

            .hr-div {
                min-width: 90%;
            }
        }

        .output-table-wrapper {
            margin-top: 1rem;

            table>thead>tr>th {
                padding: 0.5rem 1rem !important;
            }

            table>tbody>tr>td {
                background-color: white !important;
                padding-left: 1rem;
            }

            table>tbody>tr>td.publish-toggle {
                color: $wrampsWaterBlue !important;
                background-color: white !important;

                label {
                    color: $wrampsWaterBlue !important;
                }
            }

            .button-spacer {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 0.8rem;
            }
        }
    }

    .public-config-elements,
    .preview-public-elements {

        .subpage-container {
            .tab-header {
                color: black;
                font-size: 1.3rem;
                font-weight: 600;
                margin: -0.5rem 1rem 0.75rem 0;
                text-align: left;
            }

            .ui.accordion.fluid {
                text-align: left;

                .title {
                    color: $navbarBlue;
                    text-shadow: $accordionTextShadow;
                    font-weight: 700;
                    font-size: 1.3em;
                }

                .content.active {
                    padding: 0 1.5rem 1.25rem;
                    // rest of styling found inside tab either .public
                }
            }
        }
    }
}