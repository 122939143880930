@import "../../globalStyles.scss";

.welcome-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: calc(100vh - #{$navHeightDesktop});
    max-height: calc(100vh - #{$navHeightDesktop});
    overflow-x: hidden;
    background-color: #f5f5f5;

    &__container {
        max-width: 1440px;
        width: 100%;
    }

    .welcome-page-inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        background-color: white;
        flex: 1;
        width: 100%;

        .first-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-width: 100%;
            background: url("../../images/welcomewave2.png");
            background-size: cover;
            background-position: center;
            ;

            .header-div {
                .header {
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    background-clip: text;
                    text-align: center;
                    padding: 1rem 1.5em 0 1.5em;
                    margin: 0.5rem 0 2rem;
                    color: white;
                    text-shadow: $blackTextShadow;
                    font-family: $fontFamilyMain !important;
                    font-size: 4rem;
                    font-weight: bolder !important;
                    line-height: 4.5rem;
                }
            }

            .main-text-div {
                flex: 3;
                background-color: white;
                padding: 1.75rem 3rem 1.3rem;
                margin: 0 1rem 3rem;
                border-radius: 20px;
                box-shadow: $boxShadow3;
                width: 65em;

                &>h4,
                p {
                    text-align: start;
                }

                .content-divs {
                    display: inline-block;
                    font-family: 'Montserrat', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1.4;
                    width: 100%;
                    text-align: justify;
                    margin-bottom: 0.2rem;
                }
            }

            .white-wave-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                height: 3rem;
                overflow: hidden;
                margin-bottom: -5px;

                .white-wave {
                    background-color: transparent;
                    width: 100%;
                    margin: 0 !important;
                    background-image: url("../../images/wavy-border.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: bottom center;
                    height: 7rem;
                }
            }
        }

        .small-device-message {
            display: none;
        }

        .tab-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .menu-div {
                width: 100%;
                padding: 1rem 0 0;
                margin: 0 auto;
            }

            .first-tab,
            .second-tab,
            .third-tab,
            .fourth-tab {
                width: 100%;
                min-height: fit-content;
            }
        }

        // blue wave footer
        .footer-wave-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 25px 0 0 0;
            width: 100%;
            height: 100%;
            flex: 1;

            .blue-wave {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                background-color: transparent;
                width: 100%;
                flex: 1;

                .wave-background {
                    min-height: 2.8rem !important;
                    background-image: url("../../images/footer-wave.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top center;
                    margin-bottom: 0px;
                }

                .wave-filler {
                    width: 100%;
                    min-height: 3rem;
                    background-color: #89C6E9;
                }
            }
        }
    }


    &__header-title-container {
        color: white;
        text-align: left;
        max-width: 560px;
        margin: 2.6em 0 0 4em;
        border-radius: 2em;
        background: #00000047;
        padding: 2em;
        border: solid 1px #3e5969;

        /* Styles for mobile devices */
        @media only screen and (max-width: 675px) {
            text-align: center;
            margin: 2.6em auto 0 auto;
        }

        @media only screen and (max-width: 415px) {
            margin: 0.6em auto 0 auto;

        }
    }

    &__card-btn {
        width: 100%;
        margin-top: 0.51em;
        height: 2.5em;
    }

    .ui.massive.button,
    .ui.massive.buttons .button,
    .ui.massive.buttons .or {
        font-size: 1.4em !important;
    }


    &__header-title {
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: white;
        font-size: 2.2em;
        text-align: left;
        font-size: 4em;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 0.6em;

        @media only screen and (max-width: 675px) {
            font-size: 3em;
            text-align: center;

        }
    }

    &__header-subtitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media only screen and (max-width: 675px) {
            font-size: 1.2em;
            text-align: center;
        }
    }

    &__header-background-img {
        min-width: 100%;
        background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .235)), url("../../images/0072-ima.jpg");
        background-size: cover;
        filter: brightness(1.3);
        background-position: center;
        background-repeat: no-repeat;
        height: 440px;
        max-width: 1440px;
        width: 100%;

        @media only screen and (max-width: 750px) {
            height: 450px;
        }
        @media only screen and (max-width: 550px) {
            height: 380px;
        }

    }

    &__cards-container {
        display: flex;
        margin-top: 5em;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        min-height: 40em;
        flex-shrink: 0;
        margin-bottom: -20px;

        @media only screen and (max-width: 750px) {
            margin-top: 2.5em;
        }

    }

    &-cards {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        max-width: 100%;
        position: relative;
        flex-direction: column;
        width: 345px;
        min-height: 0;
        margin: 1em;
    }

    &-card {
        margin-right: 25px;
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
        background-color: #fff;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
        border: solid 1px #e0e0e0;
        min-height: 34em;

        .ui.medium.image,
        .ui.medium.images .image,
        .ui.medium.images img,
        .ui.medium.images svg {
            height: 210px !important;
            width: 100%;

        }

        &:hover {
            box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
            transform: scale(1.10, 1.10);
        }
    }


    &__card-img-container {
        visibility: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 235px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

    }

    &__card__info {
        z-index: 2;
        background-color: #fff;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 16px 24px 24px 24px;
        text-align: left;
    }

    &__card__category {

        font-size: 13px;
        letter-spacing: 0.2px;
        font-weight: 500;
        color: rgba(0, 0, 0, .68);
        text-align: left;
        min-height: 12em;
    }

    &__card__title {
        margin-top: 5px;
        margin-bottom: 1em;
        text-align: left;
        font-size: 1.65em;
    }

    &__footer-text {
        background: #89c6e9;
        font-size: 12px;
        margin: -35px 0 0em 0;
    }
}

// for seriously big screens :)
@media only screen and (min-width: 3000px) {
    .welcome-page {
        background-color: #f5f5f5;

        .welcome-page-inner-wrapper {
            max-width: 180rem;
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    .welcome-page {
        .welcome-page-inner-wrapper {
            .first-div {
                .header-div {
                    .header {
                        font-size: 1.7rem !important;
                        padding: 1rem 1.5rem 0 1.5rem;
                        margin: 0.5rem 0 2rem;
                        line-height: 2rem;
                    }
                }

                .main-text-div {
                    padding: 1.3rem 1.5rem 1rem;
                    margin: 0 1rem 2.5rem;

                    .content-divs {
                        font-size: 1rem;
                        line-height: 1.2rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 367px) and (max-width: 479px) {
    .welcome-page {
        .welcome-page-inner-wrapper {
            .first-div {
                .header-div {
                    .header {
                        font-size: 1.7rem !important;
                        padding: 1rem 1.5rem 0 1.5rem;
                        margin: 0.5rem 0 2rem;
                        line-height: 2rem;
                    }
                }

                .main-text-div {
                    padding: 1.3rem 1.5rem 1rem;
                    margin: 0 1rem 3rem;

                    .content-divs {
                        font-size: 1rem;
                        line-height: 1.2rem;
                    }
                }
            }

            .tab-div {

                .list-subtitle,
                .benefits-subtitle,
                .map-subtitle,
                .dashboard-subtitle {
                    padding: 0 1.5rem !important;
                    font-size: 1rem !important;
                    line-height: 1.2rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 368px) {
    .welcome-page {
        .welcome-page-inner-wrapper {
            .first-div {
                .header-div {
                    .header {
                        font-size: 1.7rem !important;
                        padding: 1rem 1.5rem 0 1.5rem;
                        margin: 0.5rem 0 2rem;
                        line-height: 2rem;
                    }
                }

                .main-text-div {
                    padding: 1.1rem 1.3rem 0.8rem;
                    margin: 0 1rem 3rem;

                    .content-divs {
                        font-size: 1rem;
                        line-height: 1.2rem;
                    }
                }
            }

            .small-device-message {
                display: block;
                font-size: 1.8rem;
                font-weight: 600;
                line-height: 1.8rem;
                padding: 1.5rem 1rem 0;
            }

            .tab-div {
                display: none !important;
                visibility: hidden !important;

                .menu-div {
                    display: none !important;
                    visibility: hidden !important;
                }

                .first-tab {
                    display: none !important;
                    visibility: none !important;
                }
            }
        }
    }
}