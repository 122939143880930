@import "../../globalStyles.scss";

.capture-dashboard {
    min-height: calc(100vh - 4.6rem);
    max-height: calc(100vh - 4.6rem);
    overflow-x: hidden;
    background-color: #F2F2F2;
    background-color: #f8f8f8;
    background-color: white;
    text-align: start;
    overflow: auto;

    &__page-header {
        align-items: center;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-width: 100%;
        background: url("../../images/welcomewave2.png");
        border-bottom: #80808026 solid 1px;
        background-size: cover;
        background-position: center;
        margin-bottom: 2em;
        text-align: center;
        background: linear-gradient(134deg, rgb(6 147 227 / 89%), rgba(131, 195, 239, 0.61));

        &--title {
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: white;
            text-shadow: 3px 2px 1px #4b626e;
            font-size: 3.5em;

            &-sub-title {
                font-size: 1.5em;
                font-weight: bolder;
                font-style: italic;
                color: rgb(65, 65, 65);
                text-shadow: 0px 2px #c6e1ff78;
            }
        }

    }

    &__container {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 1em 5em 3em;

        @media only screen and (max-width: 745px) {
            padding: 1em;

        }
    }

    &__widget-container {
        background-color: white;
        padding: 2em;
        margin-bottom: 2em;
        border-radius: 16px;
        box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1), -3px -3px 3px 3px rgba(247, 251, 255, 0.5), 3px 3px 8px 2px rgba(0, 0, 0, 0) inset, -3px -3px 7px 2px rgba(247, 251, 255, 0) inset;
        border: solid 1px rgb(128 128 128 / 34%);
        background: linear-gradient(325deg, rgb(96 179 235), rgb(6 147 227 / 0%));

        @media only screen and (max-width: 745px) {
            padding: 1em;

        }

        & h1,
        h2 // h3,

        // h4,
            {
            text-shadow: 2px 2px #ffffff7a;
        }
    }

    &__widget-sticky-container {
        background-color: white;
        border-radius: 16px;
        width: 100%;
        max-width: 1300px;
        z-index: 1000;

        @media (min-width: 616px) {
            position: sticky;
            top: 0;
        }
    }

    &__title-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;

        }

        &-type-section {
            display: flex;

            @media only screen and (max-width: 1120px) {
                flex-wrap: wrap;
                position: relative;
                margin-top: 1em;
            }

            @media only screen and (max-width: 745px) {
                flex-wrap: wrap;

            }
        }

        &-title {
            margin: 0.6em 0.4em 0 0;
            color: #494848;

            @media only screen and (max-width: 745px) {
                margin: 0 0.4em 0 0;

            }
        }
    }

    & .legend-wrapper .legend {
        z-index: 999 !important;
    }

    &__option-menu {
        margin-top: -14px;

        @media only screen and (max-width: 915px) {
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 615px) {
            margin: 0em 0em 1em 0;
        }
    }

    &__filter-btn {
        height: 3em;
        margin: 0.851em 2em 0px 0px !important;

        @media only screen and (max-width: 615px) {
            margin: 1.851em 2em 0px 0px !important;
        }
    }

    &__shadow {
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    &__date-banner-container {
        margin: -1em 0px 0.85em !important;
        height: fit-content;
    }

    .data-block-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 3em 0;

        @media only screen and (max-width: 615px) {
            flex-wrap: wrap;
            margin: 3em auto;
        }

        &__block-box {
            display: flex;
            margin: 0 auto 2.5em;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
                margin: 0px;

            }
        }


        &__button {
            border: "solid 1px  #006ba5";
            border: solid #8da4b864 1px !important;
            box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23);

            &--active {
                background-color: $capturegrayblue !important;
                color: white !important;
            }
        }
    }

    &__project-overview {

        &-title {
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 1em;
        }

        &-attribute-container {
            background: white;
            padding: 2em;
            border-radius: 1em;
            box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
        }

        &-attribute-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 3em;

            @media only screen and (max-width: 785px) {
                flex-direction: column;
                margin-bottom: 1em;
            }

        }


        &-attribute-title {
            padding: 4px;
            background: rgba(230, 249, 255, 0.7529411765);
            border-radius: 7px;
            color: #187dcd;
            width: -moz-fit-content;
            width: fit-content;
            block-size: -moz-fit-content;
            block-size: fit-content;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 0.3em 0;

            @media only screen and (max-width: 785px) {
                text-align: center;
                margin: 0 auto 0.75em;

            }

        }

        &-attribute-value {
            font-size: 1.2em;
            margin: 0 0 1.52em 0.25em;
            word-spacing: 1px;
            background-clip: text;

            @media only screen and (max-width: 785px) {
                text-align: center;
            }

        }
    }

    .map-wrapper {
        height: 0px;
    }

    &__button {
        background-color: $primaryColor !important;
        color: white !important;

        &--circular {
            border-radius: 3em !important;
            height: 4em !important;
            width: 4em;
            box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important;

            /* Styles for mobile devices */
            @media only screen and (max-width: 915px) {
                position: absolute;
                right: -0.65em;
                bottom: -0.5em;

            }
        }

        &:hover {
            background-color: $primaryColorHov !important;
            color: white !important;
        }

        &-cheap {
            margin-right: 0.67em !important;
            padding: 0.41em 0.85em !important;
            border: solid #187ccd86 1px !important;
            width: -moz-fit-content;
            width: fit-content !important;
            block-size: -moz-fit-content;
            block-size: fit-content;
            font-size: 14px !important;
            font-weight: bold;
            margin: 0 0 0.3em 0;

            &:hover {
                background-color: rgb(30, 84, 131) !important;
                color: white !important;
            }

            &--not-active {
                background: rgba(230, 249, 255, 0.7529411765) !important;
                border: solid #8da4b864 1px !important;
                background: #d3dae0 none !important;
                color: #373e45 !important;

                &:hover {
                    background-color: rgb(70, 101, 128) !important;
                    color: white !important;
                }
            }
        }

        &--active {
            background-color: $capturegrayblue !important;
            color: white !important;
        }

        &--sm {
            margin-bottom: 1em !important;
        }

    }

    &__sg-icon {
        background: url("../../images/SpreadingGroundCircle.svg") center center no-repeat;
        height: 20px;
        width: 32px;
        display: block;
        margin: 0 4px 0 -7px;
        
        @media only screen and (max-width: 550px) {
            margin: auto 5px auto -4px;
            width: 45px;
        }

    }
    &__sg-icon-container {
        display: flex;
        background: white;
        padding: 0.31em 0.5em;
        min-height: 2em;
        border-radius: 10px;
        justify-content: space-between;
        border: solid rgba(146, 10, 23, 0.2705882353) 1px;
    }

    &__sg-label-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 0.5em;
    }

    &__graph-dropdown {
        max-width: 16em;
        margin-right: 2em;
    }

    &__input-label {
        margin: 0 0 0 3px;
        color: #545454;
        font-weight: bold;
    }

    &__date-range-picker {
        box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important;

        &-container {
            animation: fadeIn 0.5s ease-in-out forwards;
        }

    }

    &__fade-in {
        animation: fadeIn 0.71s ease-in-out forwards;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    //Map's Popup Styles
    .popup-wrapper .esri-popup__main-container .popup-container-wrapper,
    .popup-wrapper .custom-popup-wrapper .popup-container-wrapper {
        border: solid 1px #016ba5;
    }

    .popup-wrapper .esri-popup__main-container .popup-container-wrapper,
    .popup-wrapper .custom-popup-wrapper .popup-container-wrapper {
        background: linear-gradient(to bottom, #fefefe, #98d1f1);
    }

    .popup-wrapper .esri-popup__main-container .popup-container-wrapper .popup-container .popup-text,
    .popup-wrapper .custom-popup-wrapper .popup-container-wrapper .popup-container .popup-text {
        text-align: center;
        color: #443c3c;
        font-weight: bold;
    }

    .popup-wrapper .esri-popup__main-container .popup-container-wrapper .popup-container .popup-icon,
    .popup-wrapper .custom-popup-wrapper .popup-container-wrapper .popup-container .popup-icon {
        //  clouds 0
        background-size: 120px 63px;
        width: 139px;
        height: 121px;
        top: -51px;


    }

    .basic-input-label-wrapper {}

    .multiSelectContainer .search-wrapper {
        background-color: #f8f8f8;
        margin-top: -4px;
    }

    .multiselect-container .multiSelectContainer {
        margin-top: -4px !important;
    }

    .multiSelectContainer .searchWrapper {
        min-height: 40px;
    }

    .multiSelectContainer .search-wrapper {
        min-width: 250px !important;
        max-width: 250px !important;
    }

    &__system-wide-project-overview {

        .ui.table thead {
            box-shadow: none;
            text-align: center;
        }

        table tbody>tr>td {
            color: #0a0a0a !important;
            font-weight: normal;
            text-align: center !important;
        }
    }

    &__project-overview-attribute-container {

        &-center-column {
            margin-left: -8em;

            @media only screen and (max-width: 885px) {
                margin: 0em 0em 1em;
            }
        }

        &-side-column {
            margin-left: 2em;

            @media only screen and (max-width: 885px) {
                margin: 0em 0em 1em;

            }
        }

    }

}


// Over write global styles
:where(.css-dev-only-do-not-override-1qhpsh8).ant-picker-dropdown .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.88) !important;
}

.ant-picker-content>tbody>tr:nth-child(2n) {
    background-color: white !important;
}

.ant-picker-panels {
    @media (max-width: 750px) {
        flex-direction: column !important;

    }
}


.ui.blue.message {
    background-color: #ffffff;
    color: #2185d0;
    border: solid 1px #2185d0;
    border: none;
    box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
    box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important;
}


:where(.css-dev-only-do-not-override-1qhpsh8).ant-picker .ant-picker-suffix>* {
    color: $capturegrayblue;
    font-size: 1.83em;
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    box-shadow: 12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important;

}

.gradient {
    background: linear-gradient(334deg, #f7faff, #187dcd, #faf9fd);
    background: linear-gradient(134deg, rgb(6 147 227 / 89%), rgba(131, 195, 239, 0.61));
    background-size: 150% 100%;
    animation: gradient-animation 8s cubic-bezier(0.27, 0.01, 0.55, 0.81) infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}