@import "../../../globalStyles.scss";


.file-upload-entry-icon:hover {
    cursor: pointer !important;
}

.user-management-modal-component,
.owner-management-modal-component {

    .primary {
        color: $primaryColor !important;
    }

    .secondary {
        color: $secondaryColor !important;
    }

    .tertiary {
        color: $tertiaryColor !important;
    }

    .primary-background {
        background-color: darkgray !important;
        color: white;
    }

    .secondary-background {
        background-color: darkgray !important;
        color: white;
    }

    //this is the current style
    .tertiary-background {
        background-color: $waveColor !important;
        color: $primaryColor !important;
    }

    .divider-modal {
        border-top: 1px solid #003f4780 !important;
    }

    .close {
        box-shadow: none !important;
    }

    .project-user-label {
        color: $primaryColor !important;
        font-weight: 700;
        margin-bottom: 0.3em;
    }

    .added-user {
        color: #3c9a3c;
    }

    .removed-user {
        color: #9a3c3c;
    }

    .search-user {
        min-height: 200px;
        overflow: auto;
        padding: 1em 0 1em 0;
    }

    .user-list {
        padding: 0.15em 0 0.15em 0;
    }

    // controls color of 'Search for users to add'
    .no-results {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: black !important;
        padding-top: 1em;
    }

    .project-user-list {
        max-height: 200px;
        overflow: auto;
    }

    .error-btn {
        max-height: 2.5em;
        min-height: 2.5em;
    }

    .try-again-btn {
        max-height: 2.5em;
        min-height: 2.5em;
        min-width: 7em;
    }

    .upload-modal-note {
        color: $dangerRed;
        font-weight: bold;
        font-style: italic;
    }

    .text-danger {
        color: red !important;
    }
}

.pull-right {
    float: right;
}

// main modal styling by SS starts here
.ui.modal.transition.visible.active.user-management-modal-component,
.ui.modal.transition.visible.active.owner-management-modal-component {
    border-radius: 15px !important;
    overflow: hidden !important;

    .header {
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
        border-bottom: none !important;
        background-color: $waveColor !important;
        color: $primaryColor !important;

        .user-modal-title {
            padding: 2rem 0 0 2rem;
        }

        .wave-div {
            background-color: white;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }

    .actions {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        border-top: none !important;
        overflow: hidden !important;

        .actions-div {
            display: flex;
            flex-direction: column;
            align-content: center;

            .cancel-btn {
                background-color: gray;
                color: white;
            }

            .cancel-btn:hover {
                background-color: $mediumgrey;
            }

            .wave-div {
                display: flex;
                flex-direction: row;
                align-content: flex-end;
                align-items: center;
                background-color: white;
                padding: 0;
                margin: 0;

                img {
                    width: 100%;
                }
            }

            .btn-div {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 0 1.5rem 1.5rem 0;
                background-color: $waveColor;

                button {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}