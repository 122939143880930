// this gives you access to the site wide global styles
@import "../../../globalStyles.scss";

.ui.modal.transition.visible.active.queue-modal-component {
    border-radius: 15px !important;
    overflow: hidden !important;
    margin-bottom: 2rem !important;

    .queue-error-none {
        color: #acacac !important;
    }

    .header {
        font-family: $fontFamilyMain;
        font-weight: normal;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
        border-bottom: none !important;
        background-color: $waveColor !important;
        color: $primaryColor !important;

        .queue-modal-title {
            padding: 1rem 0 0 2rem;
        }

        .queue-modal-title-subtext {
            padding: 0.5rem 2.7rem;
            margin: 0 -0.5rem;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.1rem;
            color: #000;
            background-color: white;
            background-image: url('../../../images/modalWave.svg');
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            height: 5rem;
        }
    }

    .content {
        padding: 0 !important;
        margin: 0 0 !important;

        .table-wrapper,
        .message-wrapper {
            margin: 0 !important;
            padding: 0 4rem;

            .modal-scrollable {
                margin: 1rem 0 1rem;
                overflow-y: auto;
                max-height: 48rem;
                border-radius: 0.5rem;
                border: 1px solid lightgray;
            }

            //this prevents grey scrollbar track corner from showing outside modal-scrollable
            .modal-scrollable::-webkit-scrollbar-track {
                border-radius: 0.5rem;
            }
        }

        .ui.table thead {
            position: sticky !important;
            top: 0;
            z-index: 2;
        }
    }

    .ui.message {
        margin: 0;
        background: #f8f8f9;
        padding: 0 !important;
        line-height: 3rem;
        color: rgba(0, 0, 0, .87);
        border-radius: 0.5rem;

        .content {
            .header {
                margin: 0 !important;
                padding: 0 1rem !important;
                position: sticky !important;
                top: 0;
                z-index: 2;
            }

            p {
                padding: 0.5rem 1rem;
                line-height: 1.3rem;
            }
        }
    }

    .actions {
        width: 100% !important;
        padding: 0 0 !important;
        margin: 0 !important;
        border-top: none !important;
        overflow: hidden !important;
        background-color: white;

        .wave-div {
            display: flex;
            flex-direction: row;
            align-content: flex-end;
            align-items: center;
            background-color: white;
            padding: 0;
            margin: 0.5rem 0 0;

            img {
                width: 100%;
            }
        }

        .btn-wrapper {
            background-color: $waveColor;
            padding: 0 1.5rem 1.25rem 0;
            margin-top: -1.75rem;
        }
    }
}