@import "../../../globalStyles.scss";

.ui.modal.transition.visible.active.excel-upload-modal-component {
    border-radius: 15px !important;
    overflow: hidden !important;

    .header {
        font-family: $fontFamilyMain;
        font-weight: normal;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
        border-bottom: none !important;
        background-color: $waveColor !important;
        color: $primaryColor !important;

        .excel-upload-modal-title {
            padding: 2rem 0 0 2rem;
        }

        .wave-div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-content: center;
            background-color: white;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }

    .content {
        margin: 0 !important;

        .description {

            .inner-wrapper {

                .uploader {
                    margin-bottom: 0 !important;

                    .file-upload {
                        display: block;
                        text-align: center;

                        .file-select {
                            cursor: pointer;
                            background-color: white;
                            text-align: left;
                            overflow: hidden;
                            position: relative;
                            padding: 0 !important;
                            margin: 0 !important;
                            border: 2px solid #dcdcdc;
                            border-radius: 5px;
                            cursor: pointer;

                            .file-select-button {
                                display: inline-flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                background: #dcdcdc;
                                border-radius: 3px 0 0 3px;
                                margin-left: 0.4px;
                                padding: 0 1rem;
                                height: 39.6px;
                                color: $primaryColor;
                                font-weight: 600;
                                cursor: pointer;
                            }

                            .file-select-name {
                                display: inline-flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 0 10px;
                                color: $primaryColor;
                                background-color: white;
                                margin: auto 0;
                                height: 40px;
                            }
                        }
                    }

                    // dropdown to select upload type
                    .excel-dropdown-div {
                        //keeping for possible styling if needed
                    }
                }
            }
        }
    }

    .action-div {
        overflow: hidden;
        border-radius: 0 0 10px 10px;

        .wave-div {
            display: flex;
            flex-direction: row;
            align-content: flex-end;
            align-items: center;
            background-color: white;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
            }
        }

        .actions {
            width: 100% !important;
            padding: 0 1rem 1rem 0 !important;
            margin: 0 !important;
            border-top: none !important;
            overflow: hidden !important;
            background-color: $waveColor;
        }
    }
}

.bulk-upload {
    .primaryBtn {
        background-color: red !important;
        color: white !important;
    }


    .primaryBtn:hover {
        background-color: $wrampsWaterBlueHov !important;
        color: $primaryColor !important;

    }


    .secondaryBtn {
        background-color: $secondaryColor !important;
        color: white !important;
    }

    .secondaryBtn:hover {

        background-color: $secondaryColorHov !important;
        color: #00444d !important;
    }

    .tertiaryBtn {
        background-color: $primaryColor !important;
        color: white !important;
    }

    .tertiaryBtn:hover {
        background-color: $primaryColorHov !important;
        color: white !important;
    }


    .file-upload-entry-icon:hover {
        cursor: pointer !important;
        transform: scale(1.2) !important;
    }
}

.primaryBtn {
    background-color: $primaryColor !important;
    color: white !important;
}


.primaryBtn:hover {
    background-color: $primaryColorHov !important;
    color: white !important;
}

.secondaryBtn {
    background-color: $tertiaryColor !important;
    color: #454545 !important;
    white-space: nowrap;
}

.secondaryBtn:hover {
    background-color: $secondaryColorHov !important;
    color: #00444d !important;
}

.tertiaryBtn {
    background-color: $primaryColor !important;
    color: white !important;
}

.tertiaryBtn:hover {
    background-color: $primaryColorHov !important;
    color: white !important;
}

.excel-upload-modal-component {
    .excel-dropdown-div {
        .ui.selection.dropdown.dropdown-element {
            min-width: 50% !important;
        }
    }

    .pdf-modal-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .pdf-modal-success-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .close {
        box-shadow: none !important;
    }

    .uploader-container-100 {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .uploader-container-40 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
    }


    .uploader-header-div {
        display: flex;
        justify-content: center;
    }

    .uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .error-container {
        display: flex;
        flex-direction: column;
        background-color: $waveColor;
    }

    .error-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 1em;
    }

    .additional-info-error-text {
        text-align: left !important;
    }

    .error-btn {
        max-height: 2.5em;
        min-height: 2.5em;
    }

    .try-again-btn {
        max-height: 2.5em;
        min-height: 2.5em;
        min-width: 7em;
    }

    .upload-modal-note {
        color: $dangerRed;
        font-weight: bold;
        font-style: italic;
    }

    .text-danger {
        color: $dangerRed !important;
    }

    /*  ******************Checkmark styles***************************** */
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $wrampsWaterBlue;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px $wrampsWaterBlue;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    }

    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {

        0%,
        100% {
            transform: none;
        }

        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px $wrampsWaterBlue;
        }
    }

    /*  *****************File Upload Styles********************** */

    .file-upload.active .file-select {
        border-color: #3fa46a;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
    }

    .file-upload.active .file-select .file-select-button {
        background: #3fa46a;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
    }

    .file-upload .file-select input[type=file] {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .file-upload .file-select.file-select-disabled {
        opacity: 0.65;
    }

    .file-upload .file-select.file-select-disabled:hover {
        cursor: default;
        display: block;
        border: 2px solid #dce4ec;
        color: $primaryColor;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        margin-top: 5px;
        text-align: left;
        background: #FFFFFF;
        overflow: hidden;
        position: relative;
    }

    .file-upload .file-select.file-select-disabled:hover .file-select-button {
        background: #dce4ec;
        color: #666666;
        padding: 0 10px;
        display: inline-block;
        height: 40px;
        line-height: 40px;
    }

    .file-upload .file-select.file-select-disabled:hover .file-select-name {
        line-height: 40px;
        display: inline-block;
        padding: 0 10px;
    }
}