@import "../../globalStyles.scss";

.second-tab {
    //min width & height is set for all tabs on WelcomePage.scss

    .benefits-wrapper {
        padding: 1.5rem 2.1rem;

        .benefits-title {
            color: $wrampsWaterBlue;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 0;
            margin: 0 0 0.5rem;
        }

        .hr-line {
            height: 1px;
            margin: 0.5rem 3rem 1rem;
            background-color: lightgray;
        }

        .benefits-subtitle {
            text-align: justify;
            font-size: 1.2rem;
            font-weight: normal;
            padding: 0 3rem;
            margin: 0;

            span {
                font-style: italic;
            }
        }
    }

    .cobenefits-wrapper {

        .left-div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 0;

            .sub-header {
                font-family: 'Montserrat', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
                font-weight: 600;
                color: $primaryColor;
                padding: 0 0 2rem;
                margin: 0;
                font-size: 1.5rem;
            }

            .card-wrapper {
                display: flex;
                align-content: center;

                .water-gauge-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1.2rem 0 0.5rem 0;
                    margin: 0.75rem 0;
                    background-color: white;
                    min-width: 160px;
                    border-radius: 20px;
                    box-shadow: $boxShadow2;

                    .water-gauge-text {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        font-weight: 600;
                        font-size: 1.3rem;
                        line-height: 1.5rem;
                        width: 130px;
                        min-height: 50px;
                    }
                }

                .container-top {
                    margin-top: 0.75rem;
                }

                .container-bottom {
                    margin-top: 1.25rem;
                }
            }
        }

        .hr-rule {
            display: block;
            background-color: #d6d6d6;
            min-height: 50%;
            width: 2px;
            margin: 2rem 2.5rem;
        }

        .right-div {
            flex: 3;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 0;

            .sub-header {
                font-family: 'Montserrat', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
                font-weight: 600;
                color: $primaryColor;
                padding: 0 0 2rem;
                margin: 0;
                font-size: 1.5rem;
            }

            .top-row,
            .bottom-row {
                display: flex;
                flex-direction: row;

                .water-gauge-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0.75rem;
                    padding: 1.2rem 0 0.5rem 0;
                    background-color: white;
                    min-width: 160px;
                    border-radius: 20px;
                    box-shadow: $boxShadow2;

                    .water-gauge-text {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        font-weight: 600;
                        font-size: 1.3rem;
                        line-height: 1.5rem;
                        width: 130px;
                        min-height: 50px; //this keeps single line labels from making tile shorter
                    }
                }
            }

            .bottom-row {
                margin-top: 0.5rem; // gap between top and bottom row
            }
        }
    }
}

@media only screen and (min-width: 2200px) {
    .second-tab {
        .benefits-wrapper {
            .benefits-title {
                font-size: 2.2rem;
            }

            .benefits-subtitle {
                font-size: 1.7rem;
                line-height: 2rem !important;
            }
        }

        .cobenefits-wrapper {

            .left-div,
            .right-div {
                .sub-header {
                    font-size: 1.9rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1166px) {
    .second-tab {
        .cobenefits-wrapper {

            .left-div {
                .card-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        min-width: 160px !important;
                        min-height: 150px;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }

                    .container-top,
                    .container-bottom {
                        margin: 0 1rem;
                    }
                }
            }

            .hr-rule {
                display: none !important;
            }

            .right-div {

                .top-row,
                .bottom-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    margin: 0 !important;
                    flex-wrap: wrap;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        min-width: 160px !important;
                        min-height: 150px;
                        margin: 1rem;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }
                }

                .bottom-row {
                    margin-top: 0; // removes gap between top and bottom row
                }
            }
        }
    }
}

.co-benefits {


    &__header-background-img {
        min-width: 100%;
        background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .235)), url("../../images/003-ima-b02.jpg");
        /* Add the blur effect */
        filter: brightness(1.4);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 16em;
        max-width: 1440px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 550px) {
            height: 260px;
        }

    }


    &__header-title-container {
        color: white;
        text-align: left;
        max-width: 560px;
        margin: 5.6em 0 0 4em;
        border-radius: 2em;
        background: #00000047;
        padding: 2em;
        border: solid 1px #3e5969;
        height: 10em;

        /* Styles for mobile devices */
        @media only screen and (max-width: 675px) {
            text-align: center;
            margin: 5.6em auto 0 auto;
            margin: -0.4em auto 0 auto;
        }

        @media only screen and (max-width: 415px) {
            margin: 0.6em auto 0 auto;

        }
    }

    &__header-title {
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: white;
        font-size: 3em;
        font-weight: 700;
        line-height: 120%;
        text-align: center;

        @media only screen and (max-width: 675px) {
            font-size: 3em;
            text-align: center;

        }
    }

    &__header-title-container {
        color: white;
        max-width: 80%;
        margin: 0em 0 0 4em;
        border-radius: 2em;
        background: #00000047;
        padding: 2em;
        border: solid 1px #3e5969;
        height: auto;

        /* Styles for mobile devices */
        @media only screen and (max-width: 550px) {
            text-align: center;
            margin: 5.6em auto 0 auto;
            margin: 0em auto 0 auto;
        }
    }
}

@media only screen and (max-width: 768px) {
    .second-tab {
        .cobenefits-wrapper {

            .left-div {
                .card-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    flex-direction: row;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        min-width: 160px !important;
                        min-height: 150px;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }

                    .container-top,
                    .container-bottom {
                        margin: 0 1rem;
                    }
                }
            }

            .hr-rule {
                display: none !important;
            }

            .right-div {
                .sub-header {
                    margin: 0 3rem;
                    white-space: wrap !important;
                    line-height: 2.8rem !important;
                }

                .top-row,
                .bottom-row {
                    justify-content: center;
                    flex-wrap: wrap;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        min-width: 160px !important;
                        min-height: 150px;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }
                }

                .bottom-row {
                    margin-top: 0; // removes gap between top and bottom row
                }
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .second-tab {
        .cobenefits-wrapper {

            .left-div {
                .card-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        margin: 0.75rem !important;
                        min-width: 160px !important;
                        min-height: 150px;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }

                    .container-top,
                    .container-bottom {
                        margin: 0 1rem;
                    }
                }
            }

            .hr-rule {
                display: none !important;
            }

            .right-div {
                .sub-header {
                    margin: 0 3rem;
                    white-space: wrap !important;
                    line-height: 2.8rem !important;
                }

                .top-row,
                .bottom-row {
                    justify-content: center;
                    flex-wrap: wrap;

                    .water-gauge-container {
                        padding: 1.2rem 0 0.5rem 0;
                        min-width: 160px !important;
                        min-height: 150px;

                        .water-gauge-text {
                            min-height: 50px;
                        }
                    }
                }

                .bottom-row {
                    margin-top: 0; // removes gap between top and bottom row
                }
            }
        }
    }
}