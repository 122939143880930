@import "../../globalStyles.scss";

.first-tab {
    //min width & height is set for all tabs on WelcomePage.scss
    display: flex;
    flex-direction: row;
    justify-content: center;

    .participants {
        background-color: white;
        margin: 0;
        padding: 1.5rem 0 1.6rem;
        // margin: -79px 0 0 0;
        padding: 0 0;

        width: 100%;
        min-height: 465px;

        &__header-background-img {
            min-width: 100%;
            background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .235)), url("../../images/004-ima-b05.jpg");
            /* Add the blur effect */
            // filter: blur(3px);
            // -webkit-filter: blur(3px);
            filter: brightness(1.5);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 16em;
            max-width: 1440px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 550px) {
                height: 290px;
            }

        }

        &__header-title {
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: white;
            font-size: 3em;
            font-weight: 700;
            line-height: 120%;
            text-align: center;

            @media only screen and (max-width: 675px) {
                font-size: 3em;
                text-align: center;

            }
        }

        &__header-title-container {
            color: white;
            max-width: 80%;
            margin: 0em 0 0 4em;
            border-radius: 2em;
            background: #00000047;
            padding: 2em;
            border: solid 1px #3e5969;
            height: auto;

            /* Styles for mobile devices */
            @media only screen and (max-width: 550px) {
                text-align: center;
                margin: 0em auto 0 auto;
            }
        }

        &__sub-header-text {
            text-align: center;
            margin: 2em auto;
            line-height: 25px;
            font-size: 1.2rem;
        }

        .participants-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 3rem;
            width: 100%;

            .list-title {
                color: $wrampsWaterBlue;
                font-size: 1.4rem;
                font-weight: bold;
                padding: 0;
                margin: 0;
            }

            .ui.divider {
                width: 100%;
            }

            .list-subtitle {
                text-align: justify;
                font-size: 1.2rem;
                font-weight: normal;
                padding: 0;
                margin: 0 0 1.5rem;
            }
        }

        .wsgroup-table-container {
            color: $wrampsWaterBlue;
            color: #424141;
            padding: 0 2rem;
            display: flex;
            flex-direction: row;
            justify-content: center;

            table {
                color: $wrampsWaterBlue;
                margin: 0 1rem;

                tr.header-row {
                    white-space: nowrap;
                    height: 3em;
                }

                tr {

                    height: 3em;

                    .header {
                        height: 3em;
                        font-size: 1.3rem;
                        color: #7e7e7e;
                        padding: 0;
                    }

                    .first-column {
                        width: 20rem;
                        text-align: left;
                        padding: 0 0.5rem;
                        color: #38384e !important;
                        margin-left: 1em;
                        font-size: 1.1rem;
                    }
                }

                .row-spacer {
                    border: 0;
                    height: 0.75rem;
                }

                .center {
                    width: 8rem;
                    text-align: center;
                }


                i.icon:hover {
                    cursor: pointer;
                }
            }

            table tr:hover td {
                background-color: rgba(180, 202, 246, 0.427);
                cursor: pointer;
            }
        }

        .no-watersheds {
            font-weight: normal;
            padding: 1rem;
            margin: 3rem 5rem 1rem;
            border: 1px solid #2696d343;
            border-radius: 10px;
            background-color: #2696d316;
        }
    }
}

@media only screen and (min-width: 2200px) {
    .first-tab {
        .participants {
            .participants-header {

                .list-title {
                    font-size: 2.2rem;
                    margin: 1rem 0 0;
                }

                .list-subtitle {
                    font-size: 1.7rem;
                    line-height: 2rem;
                    margin: 0 0 2rem;
                }
            }

            .wsgroup-table-container {
                table {
                    tr {
                        .header {
                            font-size: 1.8rem;
                        }

                        .first-column,
                        .center {
                            font-size: 1.7rem !important;
                        }

                        .first-column {
                            width: 35rem;
                        }
                    }

                    .first-column,
                    .center {
                        padding: 0.75rem 1rem !important;
                    }
                }
            }

            .no-watersheds {
                font-size: 2rem;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .first-tab {
        .participants {
            min-height: 265px !important;

            .wsgroup-table-container {
                padding: 0 2rem 0 1.5rem !important;

                table {
                    tr {
                        .header {
                            font-size: 1.2rem !important;
                        }
                    }
                }
            }

            .no-watersheds {
                margin: 3rem 3rem 1rem;
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .first-tab {
        .participants {
            padding: 1rem 0 1.6rem !important;
            min-height: 200px;

            .list-title {
                font-size: 1.2rem;
                padding: 0 1.5rem !important;
                margin: 0.5rem 0 0;
            }

            .hr-line {
                margin: 1rem 1.5rem !important;
            }

            .wsgroup-table-container {
                padding: 0 0.5rem 0 0.5rem !important;

                table {
                    tr {
                        .header {
                            font-size: 1rem !important;
                        }
                    }

                    &>tr>td {
                        font-size: 1rem !important;
                        line-height: 1rem !important;
                    }
                }
            }

            .no-watersheds {
                font-size: 1rem !important;
                margin: 0 2rem 1rem !important;
            }
        }
    }
}